import '../styles/globals.scss'
import { storyblokComponents } from 'components/StoryblokComponents'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { withTranslateRoutes } from 'next-translate-routes'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

import { apiPlugin, storyblokInit } from '@storyblok/react'

import { BackendProvider } from '../context/BackendContext'

import { JobylonProvider } from '../context/JobylonListContext'
import { LinkProvider } from '../context/LinkContext'
import { ModalStateProvider } from '../context/ModalStateContext'
import { StoryDataProvider } from '../context/StoryDataContext'
import { theme } from '../styles/Theme'

import type { AppProps } from 'next/app'
import registerBuilderComponents from 'utils/register-builder-components'
import { builder } from '@builder.io/sdk'
import CookieConsentBanner from 'components/cookiebanner/CookieBanner'
import { CookiesProvider } from 'react-cookie'

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY)

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_API_KEY,
  use: [apiPlugin],
  components: storyblokComponents,
})

registerBuilderComponents()

import { Noto_Sans } from 'next/font/google'

const noto = Noto_Sans({
  weight: ['300', '400', '500', '600', '800'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--primaryFont',
})

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter()
  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(router.locale)
  }, [router.locale])

  useEffect(() => {
    const handleRouteChange = (url): void => {
      const storage = globalThis?.sessionStorage

      if (!storage) return
      // Set the previous path as the value of the current path.
      const prevPath = storage.getItem('currentPath')
      storage.setItem('prevPath', prevPath)
      // Set the current path value by looking at the browser's location object.
      storage.setItem('currentPath', url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
  const domain = process.env.NEXT_PUBLIC_VERCEL_ENV !== undefined ? 'onceupon.photo' : 'localhost'
  return (
    <>
      <div className={noto.className}>
        <CookiesProvider defaultSetOptions={{ path: '/', domain, maxAge: 365 * 24 * 60 * 60 }}>
          <CookieConsentBanner />
          <ThemeProvider theme={theme}>
            <LinkProvider links={pageProps.links}>
              <ModalStateProvider>
                <JobylonProvider jobylonlist={pageProps.jobs}>
                  <BackendProvider
                    deliveryInfo={pageProps.deliveryInfo}
                    paymentOptions={pageProps.paymentOptions}
                    christmasDeliveryDates={pageProps.christmasDeliveryDates}
                    storeData={pageProps.storeData}
                  >
                    <StoryDataProvider storyDataInfo={pageProps.storyDataInfo}>
                      <Component {...pageProps} />
                    </StoryDataProvider>
                  </BackendProvider>
                </JobylonProvider>
              </ModalStateProvider>
            </LinkProvider>
          </ThemeProvider>
          <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />
        </CookiesProvider>
      </div>
    </>
  )
}

export default appWithTranslation(withTranslateRoutes(JSON.parse(`{"defaultLocale":"en-gb","locales":["da-dk","de-de","en-au","en-gb","en-us","en-ca","es-es","fr-fr","it-it","nl-nl","nb-no","sv-se"],"routesTree":{"name":"start-creating-photobooks","paths":{"default":"start-creating-photobooks","sv-se":"borja-skapa-fotobocker"}}}`), MyApp))
