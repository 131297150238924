import DynamicLink from 'components/dynamiclink/DynamicLink'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from 'atomic'
import { storyblokEditable } from '@storyblok/react'

import ContentContainer from '../../styled/ContentContainer'

import styles from './BlackFridaySpecial.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const BlackFridaySpecial: FC<PropTypes> = ({ blok }) => {
  if (blok.hide) return null
  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <ContentContainer className={styles.contentContainer}>
        <h1>{blok.title}</h1>
        {blok.subtitle && <h2>{blok.subtitle}</h2>}
        {blok.text && <p className={styles.text}>{blok.text}</p>}
        {blok.call_to_action_button_url && (
          <div className={styles.callToActionContainer}>
            <DynamicLink linkObject={blok.call_to_action_button_url}>
              <Button>Once Upon app</Button>
            </DynamicLink>
          </div>
        )}
      </ContentContainer>
    </section>
  )
}

export default BlackFridaySpecial
