import { Box } from 'atomic'
import React from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import ProductCard from 'components/product_card/ProductCard'
import { ThemeColors } from 'atomic/styles/theme.css'

type ProductList = {
  type: 'books' | 'prints'
  title: string
  color: keyof ThemeColors
  coverImage: string
  hoverImage: string
  link: string
  width?: '100' | '75' | '50' | '25'
}

type ProductCardsProps = {
  addProductCard: ProductList[]
} & BuilderProps

const ProductCards: React.FC<ProductCardsProps> = ({ addProductCard, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Box
      display={'flex'}
      flexDirection={{ mobile: 'column', desktop: 'row' }}
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={'s'}
      marginX={'auto'}
      marginY={'none'}
      paddingX={'s'}
      style={{ maxWidth: 2240 }}
      {...cleanProps}
    >
      {addProductCard.map((product, index) => (
        <ProductCard
          key={index}
          type={product.type}
          link={product.link}
          title={product.title}
          coverImage={product.coverImage}
          hoverImage={product.hoverImage}
          color={product.color ?? 'mossa4'}
          width={product.width}
        />
      ))}
    </Box>
  )
}

export default ProductCards
