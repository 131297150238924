/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, FC, SetStateAction } from 'react'
import { SubmitMessage } from 'components/submitmessage/SubmitMessage'
import Device from '../form_fields/Device'
import Description from '../form_fields/Description'
import Screenshots, { ScreenshotsDependencies } from '../form_fields/Screenshots'
import Information from '../form_fields/Information'
import { Field, FieldValues, useForm, UseFormClearErrors, UseFormRegister, UseFormSetError } from 'react-hook-form'
import { FormFields } from '../FormFields'
import { useSafeTranslation } from 'hooks/useSafeTranslation'

type PropTypes = {
  errors: { [key: string]: string }
  register: UseFormRegister<FieldValues>
  screenshotsDependencies: ScreenshotsDependencies
}

const TechnicalIssues: FC<PropTypes> = ({ errors, register, screenshotsDependencies }) => {
  const { t } = useSafeTranslation()
  return (
    <>
      <Device
        errors={errors}
        register={register(FormFields.Device.id, {
          required: t('form_validation_text_ohnoyoumissedaspot'),
        })}
        required={true}
      />
      <Description
        errors={errors}
        register={register(FormFields.Description.id, {
          required: t('form_description_validation_text'),
        })}
        required={true}
      />
      <Screenshots errors={errors} register={register} screenshotsDependencies={screenshotsDependencies} />
      <Information header="" text={t('support_pdf_access_info_banner')} illustrationType="book-with-kludd" />
    </>
  )
}

export default TechnicalIssues
