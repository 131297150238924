import ExternalLink from 'components/externallink/ExternalLink'
import useAppLinks from 'hooks/useAppLinks'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from 'atomic'
import { storyblokEditable } from '@storyblok/react'

import isVideo from '../../../utils/is-video'
import StoryblokImage from '../../storyblokimage/StoryblokImage'
import ContentContainer from '../../styled/ContentContainer'

import styles from './GetAppSection.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const GetAppSection: FC<PropTypes> = ({ blok }) => {
  const { activeOsUrl } = useAppLinks()

  return (
    <>
      <section className={styles.container} {...storyblokEditable(blok)}>
        <ContentContainer>
          <div className={styles.grid}>
            <div className={styles.imageContainer}>
              {isVideo(blok.image.filename) && (
                <video autoPlay loop muted playsInline={true}>
                  <source src={blok.image.filename} />
                </video>
              )}
              {!isVideo(blok.image.filename) && <StoryblokImage src={blok.image.filename} alt={blok.image.alt} width={700} height={350} />}
            </div>
            <div className={styles.textContainer}>
              <p className={styles.leading}>{blok.leading}</p>
              <h2>{blok.title}</h2>
              <p>{blok.text}</p>
              <ExternalLink href={activeOsUrl}>
                <Button variant="primary" size={'l'}>
                  {blok.button_title}
                </Button>
              </ExternalLink>
            </div>
          </div>
        </ContentContainer>
      </section>
    </>
  )
}

export default GetAppSection
