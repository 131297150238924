import DynamicLink from 'components/dynamiclink/DynamicLink'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from 'atomic'
import { storyblokEditable } from '@storyblok/react'

import { trackOutboundLink } from '../../../lib/analytics-utils'
import StoryblokImage from '../../storyblokimage/StoryblokImage'
import ContentContainer from '../../styled/ContentContainer'

import styles from './TextWithLinkSection.module.scss'
import { ouTheme } from 'atomic/styles/theme.css'

type PropTypes = {
  blok?: StoryblokContent
}

const TextWithLinkSection: FC<PropTypes> = ({ blok }) => {
  return (
    <section
      className={styles.container}
      style={{ backgroundColor: blok.backgroundcolor.color, marginTop: blok.illustration ? 84 : 0 }}
      {...storyblokEditable(blok)}
    >
      <ContentContainer className={styles.contentContainer}>
        {blok.illustration && blok.illustration?.filename && blok.illustration_align === 'left' && (
          <div className={styles.leftAligned}>
            <div className={styles.illustrationContainer}>
              <StoryblokImage
                src={blok.illustration.filename}
                fill
                sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                alt={blok.illustration.name}
              />
            </div>
          </div>
        )}
        {blok.illustration && blok.illustration?.filename && blok.illustration_align === 'right' && (
          <div className={styles.rightAligned}>
            <div className={styles.illustrationContainer}>
              <StoryblokImage
                src={blok.illustration.filename}
                fill
                sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                alt={blok.illustration.name}
              />
            </div>
          </div>
        )}
        {blok.illustration && blok.illustration?.filename && blok.illustration_align !== 'left' && blok.illustration_align !== 'right' && (
          <div className={styles.illustrationContainer}>
            <StoryblokImage
              src={blok.illustration.filename}
              fill
              sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
              alt={blok.illustration.name}
            />
          </div>
        )}
        <h2>{blok.title}</h2>
        <div>
          <DynamicLink linkObject={blok.button_link}>
            <Button variant="secondary" size={'l'} onClick={() => trackOutboundLink(blok.button_link.url)}>
              {blok.button_title}
            </Button>
          </DynamicLink>
        </div>
      </ContentContainer>
    </section>
  )
}

export default TextWithLinkSection
