import React from 'react'
import { FormFields } from '../FormFields'
import * as styles from '../FormCss.css'
import { ErrorMessage } from '@hookform/error-message'
import { UseFormRegisterReturn } from 'react-hook-form'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { Box, Text } from 'atomic'

interface PropTypes {
  errors: { [key: string]: string }
  register: UseFormRegisterReturn
  required?: boolean
}

const StateProvinceRegion: React.FC<PropTypes> = ({ errors, register, required }) => {
  const { t } = useSafeTranslation()

  return (
    <Box className={`${styles.inputContainerWithFloatingLabel}`}>
      <input
        id={FormFields.StateProvinceRegion.id}
        name={FormFields.StateProvinceRegion.id}
        className={[...(errors[FormFields.StateProvinceRegion.id] ? [styles.invalid, styles.inputElement] : [styles.inputElement])].join(' ')}
        type="text"
        {...register}
        placeholder={t('form_stateprovinceregion_label')}
      />
      <label
        className={required ? `${styles.required} ${styles.labelElement}` : `${styles.labelElement}`}
        htmlFor={FormFields.StateProvinceRegion.id}
      >
        {t('form_stateprovinceregion_label')}
      </label>
      <ErrorMessage
        errors={errors}
        name={FormFields.StateProvinceRegion.id}
        render={({ message }) => (
          <Text fontSize="xs" fontWeight="normal" color="errorText">
            {message}
          </Text>
        )}
      />
    </Box>
  )
}

export default StateProvinceRegion
