/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, FC, SetStateAction } from 'react'
import { SubmitMessage } from 'components/submitmessage/SubmitMessage'
import Description from '../form_fields/Description'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { FormFields } from '../FormFields'
import OrderNumber from '../form_fields/OrderNumber'
import { useSafeTranslation } from 'hooks/useSafeTranslation'

type PropTypes = {
  errors: { [key: string]: string }
  register: UseFormRegister<FieldValues>
}

const Delivery: FC<PropTypes> = ({ errors, register }) => {
  const { t } = useSafeTranslation()
  return (
    <>
      <OrderNumber
        errors={errors}
        register={register(FormFields.OrderNumber.id, {
          required: t('form_validation_text_ohnoyoumissedaspot'),
          pattern: {
            value: /^([O|o][U|u])(\d{3,4})-(.{4,6})$/,
            message: t('form_validation_text_ohnoyoumissedaspot'),
          },
        })}
        required={true}
      />
      <Description
        errors={errors}
        register={register(FormFields.Description.id, {
          required: t('form_description_validation_text'),
        })}
        required={true}
      />
    </>
  )
}

export default Delivery
