import { FC, CSSProperties } from 'react'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { Box, Container, Text } from 'atomic'
import { CardContent, CardContentProps, CardSlider, CardSliderItem } from './CardSlider'
import { ouTheme } from 'atomic/styles/theme.css'
import { v4 as uuidv4 } from 'uuid'

import TrustpilotWidget from 'components/trustpilot/Trustpilot'

import * as css from './Hero.css'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { trackEvent } from 'lib/analytics-utils'
import { useRouter } from 'next/router'
import { useLink } from 'context/LinkContext'
import InternalLink from 'components/internallink/InternalLink'
import HeroImage from './HeroImage'

type ProductType = 'photobook' | 'print'
type HeaderCardProps = {
  type: ProductType
} & BoxProps

export const Hero: FC<BoxProps> = (props) => {
  return (
    <>
      <HeroImage title={'indexpage_title'} subtitle={'indexpage_subtitle'} />
      <Container paddingLeft={'none'} paddingRight={'none'} paddingTop={'xl'} {...props}>
        <HeaderCards marginBottom="xl" />
        <TrustpilotWidget
          variant="carousel"
          background="lav4"
          paddingY="xxl"
          paddingX={{ mobile: 'none', tablet: 's-l' }}
          borderRadius="m"
          marginLeft="s-l"
          marginRight="s-l"
        />
      </Container>
    </>
  )
}

const HeaderCards: FC<BoxProps> = (props) => {
  const { t } = useSafeTranslation()
  // const router = useRouter()

  const cardSliderItems_default: CardContentProps[] = [
    {
      title: t('Textbox_title_2'),
      subtitle: 'Textbox_body_2',
      illustration: 'stars-3',
      illustrationsWidth: '50%',
      backgroundColor: ouTheme.colors.blåbär3,
      uid: uuidv4(),
      useGetTheAppLink: true,
    },
  ]

  const cardSliderItems_blackWeek: CardContentProps[] = [
    {
      title: t('banner_web_blackweek_title'),
      subtitle: 'banner_web_blackweek_body',
      illustration: 'jar',
      illustrationsWidth: '32%',
      invertedColors: true,
      backgroundColor: ouTheme.colors.malm0,
      uid: uuidv4(),
    },
  ]

  const cardSliderItems_lastOrderDate: CardContentProps[] = [
    {
      title: t('banner_LOD_title'),
      subtitle: 'banner_LOD_body',
      illustration: 'snow-3',
      illustrationsWidth: '32%',
      invertedColors: false,
      backgroundColor: ouTheme.colors.ljung2,
      uid: uuidv4(),
      link: 'christmas-delivery',
    },
  ]

  // determine if the current date is within the date range for campaigns
  const today = new Date()
  const fromDateblackWeek = new Date('2024-11-24')
  const toDateblackWeek = new Date('2024-12-02')
  const fromDatelastOrderDate = new Date('2024-12-03')
  const toDatelastOrderDate = new Date('2024-12-16')
  const isWithinDateRangeBlackWeek = today.getTime() >= fromDateblackWeek.getTime() && today.getTime() <= toDateblackWeek.getTime()
  const isWithinDateRangeLastOrderDate = today.getTime() >= fromDatelastOrderDate.getTime() && today.getTime() <= toDatelastOrderDate.getTime()
  const cardSliderItems = isWithinDateRangeBlackWeek
    ? cardSliderItems_blackWeek
    : isWithinDateRangeLastOrderDate
    ? cardSliderItems_lastOrderDate
    : cardSliderItems_default

  return (
    <Container className={css.headerCards} {...props}>
      <ProductCard type="photobook"></ProductCard>
      <ProductCard type="print"></ProductCard>
      <CardSlider
        items={cardSliderItems}
        renderItem={({ item, isSnapPoint }) => (
          <CardSliderItem isSnapPoint={isSnapPoint} key={item.uid}>
            <CardContent {...item} />
          </CardSliderItem>
        )}
      ></CardSlider>
    </Container>
  )
}

const ProductCard: FC<HeaderCardProps> = ({ type }) => {
  const { t } = useSafeTranslation()
  const router = useRouter()
  const { getLocalePath } = useLink()

  const localizedSlug = getLocalePath(type === 'photobook' ? 'photobooks' : 'photoprints', router.locale)
  const localizedPath = `${router.locale}${localizedSlug}`

  const title = type === 'photobook' ? t('productpage_hero_photobook_header') : t('productpage_hero_prints_header')
  const imageSource = type === 'photobook' ? '/images/hero/photobooks.jpg' : '/images/hero/photoprints.jpg'
  const clickTarget = 'index_hero_product_selection'
  const id = `${clickTarget}_${type}`

  const sizes =
    type === 'photobook'
      ? [t('productpage_hero_photobook_20x20cm_size'), t('productpage_hero_photobook_27x27cm_size')]
      : [t('productpage_hero_prints_13x18cm_size')]

  const clickAction = (): void => trackEvent({ clickTarget: clickTarget, action: 'click', value: type })

  const styles = {
    background: {
      backgroundImage: `url('${imageSource}')`,
      backgroundPosition: 'right',
    },
  } satisfies Record<string, CSSProperties>

  return (
    <InternalLink href={localizedPath} id={id} onClick={clickAction}>
      <Box style={styles.background} className={css.headerCard}>
        <ProductTitle title={title} />
        <ProductSizes sizes={sizes} />
      </Box>
    </InternalLink>
  )
}

const ProductTitle: FC<{ title: string }> = ({ title }) => {
  return (
    <Container marginLeft={'none'} marginRight={'none'} className={css.headerCardTitleContainer}>
      <Text fontWeight="bold" fontSize="l">
        {title}
      </Text>
      <div></div>
      <div className={css.actionButton} />
    </Container>
  )
}

const ProductSizes: FC<{ sizes: string[] }> = ({ sizes }) => {
  return (
    <Container className={css.productSizes}>
      {sizes.map((size, i) => (
        <div className={css.productSizeBox} key={`size${i}`}>
          <Text as="span" key={size}>
            {size}
          </Text>
        </div>
      ))}
    </Container>
  )
}
