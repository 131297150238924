import React, { FC } from 'react'

import { ThemeColors } from '../../../styles/theme.css'
import { container, illustration, text } from './Emphasized.css'
import Kludd13 from '../illustrations/Kludd13'

export type PropType = {
  color?: keyof ThemeColors
  children?: React.ReactNode
}

const Emphasized: FC<PropType> = ({ children, color = 'kantarell2' }) => {
  return (
    <em className={container}>
      <Kludd13 className={`${illustration}`} color={color} />
      <span className={`${text} `}>{children}</span>
    </em>
  )
}

export default Emphasized
