// import Social from 'components/social/Social'

import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import Illustration from 'atomic/components/atoms/illustrations/Illustration'

import styles from './Offers.module.scss'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import SocialMedia, { SocialMediaLink } from 'components/builder_components/social_media/SocialMedia'

type PropTypes = {
  blok?: StoryblokContent
}

const Offers: FC<PropTypes> = () => {
  const { t } = useSafeTranslation()

  const socialMediaLinks: SocialMediaLink[] = [
    {
      label: 'Link to Instagram',
      link: 'https://www.instagram.com/onceuponapp/',
      icon: 'instagram',
    },
    {
      label: 'Link to Pinterest',
      link: 'https://www.pinterest.se/onceuponpublishing/',
      icon: 'pinterest',
    },
    {
      label: 'Link to Facebook',
      link: 'https://www.facebook.com/onceuponpublishing',
      icon: 'facebook',
    },
  ]

  return (
    <div className={styles.container}>
      <section className={styles.info}>
        <h1>{t('web_offers_ouroffers_title')}</h1>
        <p>{t('web_offers_ouroffers_text')}</p>
        <ul>
          <li>- {t('web_offers_ouroffers_discount1')}</li>
          <li>- {t('web_offers_ouroffers_discount3')}</li>
        </ul>

        <p className={styles.handwritten}>{t('web_offers_promocode')}</p>
      </section>
      <section className={styles.bottom}>
        <div className={styles.content}>
          <Illustration className={styles.illustration} name="drawing-4" />
          <div>
            <h2>{t('web_offers_dontforget_title')}</h2>
            <p>{t('web_offers_dontforget_text')}</p>
            {/* <Social className={styles.social} /> */}
            <SocialMedia className={styles.social} socialLink={socialMediaLinks} />
          </div>
        </div>
      </section>
    </div>
  )
}

export default Offers
