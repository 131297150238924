import { Box, Container, Text } from 'atomic'
import { ThemeColors } from 'atomic/styles/theme.css'
import { useBackend } from 'context/BackendContext'
import { useRouter } from 'next/router'
import React from 'react'

type ChristmasDeliveryProps = {
  color?: keyof ThemeColors
}
const ChristmasDelivery: React.FC<ChristmasDeliveryProps> = ({ color, ...props }) => {
  const { christmasDeliveryDates } = useBackend()
  const router = useRouter()

  const getDate = (dateString: string): string => {
    const date = new Date(dateString.substring(0, 10)) // only take YYYY-mm-dd from the date we get (in ISO format), to avoid timezone issues
    const localeString = date.toLocaleString(router.locale, {
      month: 'long',
      day: 'numeric',
      timeZone: 'Europe/Stockholm',
    })

    return localeString
  }

  return (
    <Box paddingX={{ mobile: 's', desktop: 'none' }}>
      <Container
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        justifyContent={'center'}
        gap={'m'}
        paddingX={'none'}
        style={{ maxWidth: 940 }}
        {...props}
      >
        <Box style={{ width: '100%' }} display={'flex'} flexDirection={'column'} gap={'s'}>
          {christmasDeliveryDates?.countries.map((country) => (
            <Box
              key={country.country_code}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              padding={'m'}
              backgroundColor={color ?? 'malm4'}
              style={{ width: '100%' }}
            >
              <Text fontSize={'base'} fontWeight={'extrabold'} marginBottom={'none'}>
                {country?.country}
              </Text>
              <Text fontSize={'base'} fontWeight={'extrabold'} marginBottom={'none'} textAlign={'right'}>
                {getDate(country?.last_order_date)}
              </Text>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default ChristmasDelivery
