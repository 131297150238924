import { Box } from 'atomic'
import React, { AllHTMLAttributes, FC, FormEvent, useRef, useState } from 'react'
import { Loadingstatus } from 'common/enums'
import axios from 'axios'
import log from 'utils/logger'
import * as css from '../../contact_forms/ContactForms.css'
import Modal from 'components/modal/Modal'
import { Button } from 'atomic'
import ReCAPTCHA from 'react-google-recaptcha'
import FormInput from '../form_components/form_input/FormInput'
import FormTextArea from '../form_components/form_text_area/FormTextArea'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { sprinkles, Sprinkles } from 'atomic/styles/sprinkles.css'
import { InputSize } from 'atomic/components/atoms/input/Input'
import { parseSprinkleProps } from 'atomic/components/atoms/helpers/sprinkleHelper'
import { inputSize } from 'atomic/components/atoms/input/input.css'
import { ThemeColors } from 'atomic/styles/theme.css'
import Screenshots from './form_fields/Screenshots'
import { useForm } from 'react-hook-form'
import { SubmitMessage } from 'components/submitmessage/SubmitMessage'

type FormProps = Omit<AllHTMLAttributes<HTMLInputElement>, 'className' | 'size'> &
  Sprinkles & {
    className?: string
    size: InputSize
    color?: keyof ThemeColors
  }

const FeedbackForm: FC<FormProps> = ({ className, size = 'm', borderRadius = 's', fontWeight = 'normal', color, ...props }) => {
  const { t } = useSafeTranslation()
  const { sprinkleProps, otherProps } = parseSprinkleProps(props)
  const [formData, setFormData] = useState({ message: '', email: '' })
  const [loadingStatus, setLoadingStatus] = useState<Loadingstatus>(Loadingstatus.IDLE)
  const recaptchaRef = useRef(null)

  const subject = (): string => {
    return `Feedback`
  }

  const message = (): string => {
    return `
    <br/>
    <b>Email:</b> ${formData.email}<br/>
    <b>Message:</b> ${formData.message}<br/>
    <br/>
    `
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    recaptchaRef.current.reset()
    setLoadingStatus(Loadingstatus.LOADING)
    let token = ''
    try {
      token = await recaptchaRef.current.executeAsync()
    } catch (error) {
      log.error(error)
    }

    const url = process.env.NEXT_PUBLIC_SUPPORT_FORM_URL

    const formDataRequest = new FormData()
    formDataRequest.append('email', formData.email)
    formDataRequest.append('captcha', token)
    formDataRequest.append('subject', subject())
    formDataRequest.append('message', message())

    const images = Object.values(files)
    images.forEach((image) => {
      formDataRequest.append('files', image)
    })

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }

    try {
      await axios.post(url, formDataRequest, config)
      setLoadingStatus(Loadingstatus.SUCCESS)
      onReset()
    } catch (error) {
      log.error(error)
      setLoadingStatus(Loadingstatus.IDLE)
    }

    // Reset the reCAPTCHA so that it can be executed again if user submits another email.
    recaptchaRef.current.reset()
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  const onReset = () => {
    setFormData({ message: '', email: '' })
    setFiles({})
  }

  const [files, setFiles] = useState<{ [key: string]: File }>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submitMessage, setSubmitMessage] = useState<SubmitMessage | null>(null)
  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onTouched' })

  return (
    <Box>
      <form name={'feedback'} onSubmit={onSubmit}>
        <Box display={'flex'} flexDirection={'column'} gap={'xxl'}>
          <Box gap={'m'} className={css.inputContainer}>
            <FormTextArea
              name="message"
              translatedText={t('Feedback_form_Share_Your_Thoughts')}
              value={formData.message}
              onChange={handleInputChange}
              style={{ width: '100%', height: 205, resize: 'none' }}
              color={color}
              className={`${className} ${inputSize[size]} ${sprinkles({ ...sprinkleProps, borderRadius, fontWeight })}`}
              required
              {...otherProps}
            />
            <FormInput
              type="email"
              name="email"
              translatedText={t('web_influencer_landingpage_form_email')}
              value={formData.email}
              onChange={handleInputChange}
              style={{ width: '100%' }}
              color={color}
              className={`${className} ${inputSize[size]} ${sprinkles({ ...sprinkleProps, borderRadius, fontWeight })}`}
              {...otherProps}
              required
            />
            <Screenshots
              errors={errors}
              register={register}
              screenshotsDependencies={{ setFiles, getFiles: files, setSubmitMessage, setError, clearErrors, required: false }}
            />
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexWrap={'wrap'}
            gap={{ mobile: 's', desktop: 'l' }}
            style={{ width: '100%' }}
          >
            <Button type="reset" size={'l'} style={{ width: 260 }} variant="secondary" onClick={onReset}>
              {t('web_influencer_landingpage_form_empty_form')}
            </Button>
            <Button type="submit" size={'l'} style={{ width: 260 }} variant="primary">
              {t('web_influencer_landingpage_form_button_text')}
            </Button>
          </Box>
        </Box>
      </form>

      <ReCAPTCHA
        ref={recaptchaRef}
        onExpired={() => recaptchaRef.current.reset()}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      />

      <Modal show={loadingStatus === Loadingstatus.SUCCESS} onClosed={() => setLoadingStatus(Loadingstatus.IDLE)}>
        <Box>
          <h1>{t('web_influencer_submition_sent_confirmation_header')}</h1>
          <p>{t('web_influencer_submition_sent_confirmation_body')}</p>
          <Box display="flex" justifyContent="center">
            <Button marginTop="xl" onClick={() => setLoadingStatus(Loadingstatus.IDLE)}>
              {t('web_influencer_submition_sent_confirmation_button')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default FeedbackForm
