import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import Illustration from 'atomic/components/atoms/illustrations/Illustration'
import { storyblokEditable } from '@storyblok/react'

import StoryblokImage from '../../storyblokimage/StoryblokImage'
import ContentContainer from '../../styled/ContentContainer'

import styles from './ImageSizeable.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const TextSection: FC<PropTypes> = ({ blok }) => {
  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <ContentContainer className={styles.contentContainer}>
        {blok.arrowText && (
          <div className={styles.arrowText}>
            <Illustration name="arrow-7" width={71} height={71} />
            {blok.arrowText}
          </div>
        )}
        <div className={styles.sizeableimage}>
          <StoryblokImage key={blok.image.id} src={blok.image.filename} width={blok.width} height={blok.height} alt={blok.image.name} />
        </div>
      </ContentContainer>
    </section>
  )
}

export default TextSection
