import DynamicLink from 'components/dynamiclink/DynamicLink'
import InternalLink from 'components/internallink/InternalLink'
import { useRouter } from 'next/router'
import { StoryblokContent } from 'types/StoryblokContent'
import React, { FC, useEffect, useState } from 'react'
import { NODE_PARAGRAPH, NODE_UL, render, MARK_LINK } from 'storyblok-rich-text-react-renderer'
import { useMediaQuery } from 'react-responsive'
import { withTheme } from 'styled-components'
import { Theme } from '../../../styles/Theme'

import { Button } from 'atomic'
import { storyblokEditable } from '@storyblok/react'

import { useLink } from '../../../context/LinkContext'
import { useModalState } from '../../../context/ModalStateContext'
import LeftArrow from '../../icons/LeftArrow'
import Video from '../video/Video'
import Youtube from '../youtube/Youtube'

import styles from './BlogPost.module.scss'
import ExternalLink from 'components/externallink/ExternalLink'

type PropTypes = {
  blok?: StoryblokContent
  theme: Theme
}

const BlogPost: FC<PropTypes> = ({ blok, theme }) => {
  const router = useRouter()

  const { showGetStoriesModal, setShowGetStoriesModal } = useModalState()
  const [ctaUrl, setCtaUrl] = useState(blok.call_to_action_button_url)
  const { getLocalePath } = useLink()
  const available_languages: string[] = ['en-gb', 'en-us', 'en-au', 'nl-nl', 'de-de', 'sv-se', 'it-it', 'fr-fr', 'es-es']
  const isAllowed: boolean = available_languages?.includes(router.locale)
  const ctaUrlOnDesktop = {
    cached_url: 'https://app.onceupon.photo/',
    fieldtype: 'multilink',
    id: '',
    linktype: 'url',
    url: 'https://app.onceupon.photo/',
  }

  let alignmentCss: string = styles.centerAlign
  if (blok.alignment === 'left') {
    alignmentCss = styles.leftAlign
  } else if (blok.alignment === 'right') {
    alignmentCss = styles.rightAlign
  }

  // check if the blog is translated to the current language/locale
  if (!isAllowed && !showGetStoriesModal) {
    setShowGetStoriesModal(true)
  }

  const isUrlPoitingToAppDownload = blok.call_to_action_button_url?.url?.indexOf('onelink') > -1
  const isDesktop = useMediaQuery({ minWidth: theme.screenSizes.large })
  useEffect(() => {
    if (isDesktop && isUrlPoitingToAppDownload) {
      setCtaUrl(ctaUrlOnDesktop)
    } else {
      setCtaUrl(blok.call_to_action_button_url)
    }
  }, [isDesktop])

  return (
    <div className={`${styles.container} ${alignmentCss}`} key={blok._uid} {...storyblokEditable(blok)}>
      <InternalLink href={getLocalePath('/stories', router.locale)}>
        <div className={styles.back}>
          <LeftArrow />
          <p>Stories </p>
        </div>
      </InternalLink>
      <div className={styles.headerContainer}>
        <div>
          <h1 className={styles.title}>{blok.title}</h1>

          {blok.author && (
            <p className={styles.author}>
              {blok.author_preposition} {blok.author?.name}
            </p>
          )}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div>
          {render(blok.long_text, {
            blokResolvers: {
              // eslint-disable-next-line react/display-name
              ['youtube']: (props) => {
                return <Youtube blok={props}></Youtube>
              },
              // eslint-disable-next-line react/display-name
              ['video']: (props) => {
                return <Video blok={props}></Video>
              },
            },
            nodeResolvers: {
              // eslint-disable-next-line react/display-name
              [NODE_PARAGRAPH]: (children, props) => {
                let paragraphContainsImg = false
                let paragraphContainsPureText = false
                for (let i = 0; i < children?.length; i++) {
                  if (children[i].type === 'img') {
                    paragraphContainsImg = true
                  } else if (children[i].type === undefined) {
                    // if type is missing it means the child contains pure text
                    paragraphContainsPureText = true
                  }
                }
                return (
                  // if a node contains both an image and pure text we prioritize styling for the text, a.k.a. narrower width :)
                  <p className={paragraphContainsImg && !paragraphContainsPureText ? styles.pFullWidth : styles.pNarrowWidth} {...props}>
                    {children}
                  </p>
                )
              },
              // eslint-disable-next-line react/display-name
              [NODE_UL]: (children, props) => {
                return (
                  // fix styling for unordered lists, so the chosen alignment moves the entire list, but the items in it are always left-aligned
                  <div className={`${styles.pNarrowWidth}`} {...props}>
                    <ul className={`${styles.unorderedList}`}>{children}</ul>
                  </div>
                )
              },
            },
            markResolvers: {
              // eslint-disable-next-line react/display-name
              [MARK_LINK]: (children, props) => {
                const { linktype, href } = props
                if (linktype === 'email') {
                  return <a href={`mailto:${href}`}>{children}</a>
                }
                if (href.match(/^(https?:)?\/\//)) {
                  return <ExternalLink href={href}>{children}</ExternalLink>
                }
                return <InternalLink href={href}>{children}</InternalLink>
              },
            },
          })}
        </div>
      </div>

      {blok.call_to_action_button_text?.length > 0 && (
        <div className={styles.callToActionContainer}>
          <DynamicLink linkObject={ctaUrl}>
            <Button>{blok.call_to_action_button_text}</Button>
          </DynamicLink>
        </div>
      )}
    </div>
  )
}

export default withTheme(BlogPost)
