import React, { useEffect, useState } from 'react'
import { Link } from 'components/link/Link'
import { trackOutboundLink } from 'lib/analytics-utils'
import builder from '@builder.io/react'
import { Box, Button, Heading, Text } from 'atomic'
import * as css from './ProductCard.css'
import PlusIcon from 'components/icons/PlusIcon'
import { ouTheme, ThemeColors, themeVars } from 'atomic/styles/theme.css'
import Image from 'next/image'
import { useBackend } from 'context/BackendContext'
import { useCmsOrTranslation, useSafeTranslation } from 'hooks/useSafeTranslation'
import { useMediaQuery } from 'react-responsive'
import RegularArrow from 'components/icons/RegularArrow'
import { Trans } from 'next-i18next'

type ProductCardProps = {
  type: 'books' | 'prints'
  link: string
  title?: string
  coverImage: string
  hoverImage: string
  color: keyof ThemeColors
  width?: '100' | '75' | '50' | '25'
}

const ProductCard: React.FC<ProductCardProps> = ({ type, link, title, coverImage, hoverImage, color, width = 100 }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { storeData } = useBackend()
  const { t } = useSafeTranslation()
  const { cmsOrLokalise } = useCmsOrTranslation()

  const booksBase = storeData?.find((product) => product.key === 'book-20x20cm-soft-cover')
  const printsBase = storeData?.find((product) => product.key === 'card-13x18cm')

  const bookPrice = `${booksBase?.price} ${booksBase?.currency}`
  const printPrice = `${printsBase?.price} ${printsBase?.currency}`

  const titleText =
    title ?? type === 'books' ? cmsOrLokalise(title, 'productpage_hero_photobook_header') : cmsOrLokalise(title, 'productpage_hero_prints_header')

  const [cardWidth, setCardWidth] = useState(100)

  const largeScreen = useMediaQuery({ minWidth: ouTheme.breakpoints.desktop })
  const mediumScreen = useMediaQuery({ minWidth: ouTheme.breakpoints.tablet, maxWidth: 1199 })
  const smallScreen = useMediaQuery({ minWidth: ouTheme.breakpoints.mobile, maxWidth: ouTheme.breakpoints.tablet })

  useEffect(() => {
    setCardWidth(smallScreen ? 100 : mediumScreen ? 100 : width === '100' ? 100 : width === '75' ? 75 : width === '50' ? 50 : 25)
  }, [mediumScreen, smallScreen])

  return (
    <Link
      href={link}
      onClick={() => {
        trackOutboundLink(link)
        builder.trackConversion()
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ width: `${cardWidth}%`, position: 'relative' }}
    >
      <Box position={'relative'} borderRadius={'l'} style={{ width: '100%' }} backgroundColor={color}>
        {!largeScreen && type === 'prints' && (
          <Image
            src={coverImage}
            width={mediumScreen ? 300 : 147}
            height={mediumScreen ? 259 : 127}
            sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
            style={{
              objectFit: 'contain',
              zIndex: 0,
              position: 'absolute',
              bottom: mediumScreen ? -90 : -40,
            }}
            alt="Picture of Once Upon's products"
          />
        )}
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          className={css.cardContainer[type]}
          style={{
            width: '100%',
          }}
        >
          <Box
            display={'flex'}
            padding={'s-l'}
            flexDirection={'column'}
            gap={{ mobile: 'xxxs', desktop: 'xxs' }}
            backgroundColor={isHovered && !smallScreen ? 'white' : 'transparent'}
            style={{
              borderBottomRightRadius: themeVars.borderRadiuses.l,
              zIndex: 1,
              paddingLeft: smallScreen && type === 'prints' ? 150 : mediumScreen && type === 'prints' ? 290 : themeVars.spaces['s-l'],
            }}
          >
            <Heading
              as={'h3'}
              fontSize={{ mobile: type === 'prints' ? 'base' : 'l', desktop: 'l' }}
              fontWeight={'semibold'}
              color={'malm0'}
              marginBottom={'none'}
              display={{ mobile: 'flex', desktop: 'block' }}
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              gap={'xxs'}
            >
              {titleText} {type === 'prints' && smallScreen && <RegularArrow />}
            </Heading>
            <Text fontSize={{ mobile: type === 'prints' ? 's' : 'base', desktop: 'base' }} fontWeight={'light'} color={'malm0'} marginBottom={'none'}>
              <Trans
                key={type === 'books' ? 'productpage_photobooks_subheading' : 'productpage_prints_subheading'}
                i18nKey={type === 'books' ? 'productpage_photobooks_subheading' : 'productpage_prints_subheading'}
                values={{ price: type === 'books' ? bookPrice : printPrice }}
                components={{
                  price: (
                    <Text as="span" fontWeight="semibold">
                      price
                    </Text>
                  ),
                }}
              />
            </Text>
          </Box>
          <Box
            display={{ mobile: smallScreen && type === 'prints' ? 'none' : 'flex', desktop: 'flex' }}
            padding={'s-l'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'xxs'}
            style={{ width: smallScreen ? '100%' : 'auto' }}
          >
            <Button
              variant={'primary'}
              size={'l'}
              gap={'xxs'}
              style={{
                display: smallScreen && type !== 'prints' ? 'none' : 'flex',
                width: themeVars.spaces.xl,
                height: themeVars.spaces.xl,
                paddingInline: 0,
                backgroundColor: !isHovered ? '#222' : 'white',
                zIndex: 1,
              }}
              onClick={() => {
                trackOutboundLink(link)
                builder.trackConversion()
              }}
            >
              <PlusIcon color={!isHovered ? 'white' : '#222'} />
            </Button>
            <Button
              variant={'primary'}
              size={'l'}
              gap={'xxs'}
              style={{
                zIndex: 1,
                display: smallScreen ? 'flex' : 'none',
                width: '100%',
                height: 48,
              }}
              onClick={() => {
                trackOutboundLink(link)
                builder.trackConversion()
              }}
            >
              {t('productselection_create_book_header')}
            </Button>
          </Box>
        </Box>
        {(type === 'books' || (type === 'prints' && largeScreen)) && (
          <Image
            src={isHovered && !smallScreen ? hoverImage : coverImage}
            fill
            sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
            style={{
              objectFit: isHovered && !smallScreen ? 'cover' : 'contain',
              zIndex: 0,
              borderRadius: themeVars.borderRadiuses.l,
              paddingLeft: type !== 'prints' && !isHovered ? 20 : 0,
              paddingTop: type !== 'prints' && !isHovered ? 20 : 0,
            }}
            alt="Picture of Once Upon's products"
          />
        )}
      </Box>
    </Link>
  )
}

export default ProductCard
