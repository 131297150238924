import React from 'react'
import { FormFields } from '../FormFields'
import * as styles from '../FormCss.css'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { Box } from 'atomic'
import { ListItem } from 'components/dropdown/Dropdown'
import DownArrow from 'components/icons/DownArrow'

interface PropTypes {
  errors: { [key: string]: string }
  list: ListItem[]
  required?: boolean
  onErrandTypeChange: (selectedValue: string) => void
}

const SelectErrandType: React.FC<PropTypes> = ({ errors, list, required = true, onErrandTypeChange }) => {
  const { t } = useSafeTranslation()
  return (
    <Box className={`${styles.inputContainerWithFloatingLabel}`}>
      <select
        id={FormFields.SelectErrandType.id}
        name={FormFields.SelectErrandType.id}
        defaultValue=""
        className={[
          ...(errors[FormFields.SelectErrandType.id]
            ? [styles.invalid, styles.inputElement, styles.dropdown]
            : [styles.inputElement, styles.dropdown]),
        ].join(' ')}
        onBlur={(e) => {
          onErrandTypeChange(e.target.value)
        }}
        onChange={(e) => {
          onErrandTypeChange(e.target.value)
        }}
      >
        <option value="" disabled hidden>
          {`-- ${t('form_errandtypes_choose')} --`}
        </option>
        {list.map((item, index) => (
          <option key={index} value={item.key} style={{ padding: '10px' }}>
            {item.label}
          </option>
        ))}
      </select>
      <DownArrow dark className={styles.dropdownArrow} />
      <label
        className={
          required
            ? `${styles.required} ${styles.labelElement} ${styles.labelElementForDropdown}`
            : `${styles.labelElement} ${styles.labelElementForDropdown}`
        }
        htmlFor={FormFields.SelectErrandType.id}
      >
        {t('form_errandtypes_header')}
      </label>
    </Box>
  )
}

export default SelectErrandType
