/*
varje ärendetyp har
label
key
input fields
required input fields
information text
*/
//type FormFieldType = 'TextField' | 'TextArea' | 'Email' | 'File' | 'Checkbox' | 'HelpText' | ''

export type FormField = {
  id: string
  label?: string
  /* description?: string
  name?: string
  elementId?: string
  placeholder?: string
  type?: FormFieldType
  validationText?: string
  fullWidth?: boolean
  validators?: { message: string; regex: string } */
}

const Firstname: FormField = {
  id: 'firstname',
  label: 'First name',
  /* description: '',
  name: 'First name',
  elementId: 'firstname',
  placeholder: '',
  type: 'TextField',
  validationText: 'Oops! Your first name goes here.',
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const Surname: FormField = {
  id: 'surname',
  label: 'Surname',
  /* description: '',
  name: 'Surname',
  elementId: 'surname',
  placeholder: '',
  type: 'TextField',
  validationText: 'Surname as well please!',
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const Email: FormField = {
  id: 'email',
  label: 'Email',
  /* description: '',
  name: 'Email',
  elementId: 'email',
  placeholder: '',
  type: 'Email',
  validationText: 'Whoopsi daisy! You forgot to enter the most important thing – your email address!',
  fullWidth: true,
  validators: { message: '', regex: '' }, */
}

const CustomInfobox: FormField = {
  id: 'custom-infobox',
  label: '',
  /* description: '',
  name: '',
  elementId: '',
  placeholder: '',
  type: 'HelpText',
  validationText: '',
  fullWidth: true,
  validators: { message: '', regex: '' }, */
}

const EmailConnectedToYourAccount: FormField = {
  id: 'email-connected-to-your-account',
  label: 'Email connected to your account',
  /* description: '',
  name: 'Email connected to your account',
  elementId: 'emailconnectedtoyouraccount',
  placeholder: '',
  type: 'Email',
  validationText: '',
  fullWidth: true,
  validators: { message: '', regex: '' }, */
}

const AddressLine2: FormField = {
  id: 'address-line-2',
  label: 'Address line 2',
  /* description: '',
  name: 'Address line 2',
  elementId: 'addressLine2',
  placeholder: '',
  type: 'TextField',
  validationText: "Oh no, you've missed a spot!",
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const StreetAddress: FormField = {
  id: 'street-address',
  label: 'Street address',
  /* description: '',
  name: 'Street address',
  elementId: 'streetaddress',
  placeholder: '',
  type: '',
  validationText: "Oh no, you've missed a spot!",
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const StateProvinceRegion: FormField = {
  id: 'state-province-region',
  label: 'State / Province / Region (Optional)',
  /* description: '',
  name: 'State / Province / Region',
  elementId: 'state/province/region',
  placeholder: '',
  type: 'TextField',
  validationText: "Oh no, you've missed a spot!",
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const Country: FormField = {
  id: 'country',
  label: 'Country',
  /* description: '',
  name: 'Country',
  elementId: 'country',
  placeholder: '',
  type: 'TextField',
  validationText: "Oh no, you've missed a spot!",
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const City: FormField = {
  id: 'city',
  label: 'Town / City',
  /* description: '',
  name: 'City',
  elementId: 'city',
  placeholder: '',
  type: 'TextField',
  validationText: "Oh no, you've missed a spot!",
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const Postcode: FormField = {
  id: 'postcode',
  label: 'Postcode',
  /* description: '',
  name: 'Postcode',
  elementId: 'postcode',
  placeholder: '',
  type: 'TextField',
  validationText: "Oh no, you've missed a spot!",
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const AppVersion: FormField = {
  id: 'appversion',
  label: 'App version',
  /* description: 'You can find your app version in your Settings > Information & Support',
  name: 'App version',
  elementId: 'appversion',
  placeholder: 'For example: 2.10.0 (528)',
  type: 'TextField',
  validationText: "Oh no, you've missed a spot!",
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const Device: FormField = {
  id: 'device',
  label: 'Device',
  /* description: 'Please describe your device and operating system (for example iPhone 14 iOS 16.6.1 or Google Chrome version 117.0)',
  name: 'Unit',
  elementId: 'unit',
  placeholder: '',
  type: 'TextField',
  validationText: "Oh no, you've missed a spot!",
  fullWidth: true,
  validators: { message: '', regex: '' }, */
}

const ChangeAddress: FormField = {
  id: 'change-address',
  label: 'Change your delivery address to:',
  /* description: '',
  name: 'Change your delivery address to',
  elementId: 'changeyourdeliveryaddressto',
  placeholder: '',
  type: 'TextArea',
  validationText: '',
  fullWidth: true,
  validators: { message: '', regex: '' }, */
}

const Screenshots: FormField = {
  id: 'screenshots',
  label: 'Photos',
  /* description: 'Please upload screenshots from your phone, tablet or computer. Maximum file size: 10 MB.',
  name: 'Photos',
  elementId: 'photos',
  placeholder: '',
  type: 'File',
  validationText: 'File too large. The maximum file size allowed is 10 MB.',
  fullWidth: true,
  validators: { message: '', regex: '' }, */
}

const Images: FormField = {
  id: 'images',
  label: 'Photos',
  /* description: 'Please upload images that can help us help you. (Maximum file size: 10 MB)',
  name: 'Photos',
  elementId: 'photos',
  placeholder: '',
  type: 'File',
  validationText: 'File too large. The maximum file size allowed is 10 MB.',
  fullWidth: true,
  validators: { message: '', regex: '' }, */
}
const DescriptionCancelOrder: FormField = {
  id: 'description-cancel-order',
  label: 'Description',
  /* description: 'Please describe the reason for your cancellation request.',
  name: '/* description',
  elementId: '/* description',
  placeholder: '',
  type: 'TextArea',
  validationText:
    "Oh how we wish that we could read minds (how cool would that be?), but unfortunately we can't. So please describe your matter with as much detail as you possible can.",
  fullWidth: true,
  validators: { message: '', regex: '' }, */
}

const Description: FormField = {
  id: 'description',
  label: 'Description',
  /* description: 'Describe your matter with as much detail as you possible can – the more we know, the faster we can help.',
  name: '/* description',
  elementId: '/* description',
  placeholder: '',
  type: 'TextArea',
  validationText:
    "Oh how we wish that we could read minds (how cool would that be?), but unfortunately we can't. So please describe your matter with as much detail as you possible can.",
  fullWidth: true,
  validators: { message: '', regex: '' }, */
}

const OrderNumber: FormField = {
  id: 'order-number',
  label: 'Order number',
  /* description: '',
  name: 'Order number',
  elementId: 'orderNumber',
  placeholder: 'For example: OU1234-A123',
  type: 'TextField',
  validationText: "Oh no, you've missed a spot!",
  fullWidth: false,
  validators: { message: 'For example: OU1234-A123', regex: '^([O|o][U|u])(d{3,4})-(.{4,6})$' }, */
}

const ReceiverFirstname: FormField = {
  id: 'receiver-firstname',
  label: 'Receiver first name',
  /* description: '',
  name: 'Receiver first name',
  elementId: 'receiverFirstname',
  placeholder: '',
  type: 'TextField',
  validationText: 'Oops! Receivers first name goes here.',
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const ReceiverSurname: FormField = {
  id: 'receiver-surname',
  label: 'Receiver surname',
  /* description: '',
  name: 'Receiver surname',
  elementId: 'receiverSurname',
  placeholder: '',
  type: 'TextField',
  validationText: 'Receivers surname as well please!',
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const ReceiverPhoneNumber: FormField = {
  id: 'receiver-phone-number',
  label: 'Receiver phone number',
  /* description: '',
  name: 'Receiver phone number',
  elementId: 'receiverPhoneNumber',
  placeholder: '',
  type: 'TextField',
  validationText: 'Receivers phone number as well please!',
  fullWidth: false,
  validators: { message: '', regex: '' }, */
}

const SelectErrandType: FormField = {
  id: 'select-errand-type',
  label: 'Select errand type',
}

export const FormFields = {
  Firstname,
  Surname,
  Email,
  CustomInfobox,
  EmailConnectedToYourAccount,
  AddressLine2,
  StreetAddress,
  StateProvinceRegion,
  Country,
  City,
  Postcode,
  AppVersion,
  Device,
  ChangeAddress,
  Screenshots,
  Images,
  DescriptionCancelOrder,
  Description,
  OrderNumber,
  ReceiverFirstname,
  ReceiverSurname,
  ReceiverPhoneNumber,
  SelectErrandType,
}
