import { Box, Container, Text } from 'atomic'
import { FC } from 'react'
import * as css from './QuoteSection.css'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type QuoteSectionProps = {
  backgroundColor?: string
  quote: string
  textAlign?: 'left' | 'center' | 'right'
  signature?: string
} & BoxProps &
  BuilderProps

const QuoteSection: FC<QuoteSectionProps> = ({ backgroundColor, quote, textAlign = 'center', fontWeight, signature, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, {
    builderBlock: true,
    builderState: true,
  })
  return (
    <Box background={backgroundColor ?? 'mossa4'} marginBottom={props.marginBottom ?? 'xxl'} {...cleanProps}>
      <Container className={css.container} {...props}>
        <Box justifyContent="center" textAlign="center">
          <Box>
            <Box textAlign="left">
              <Illustration name="drawing-21" className={css.illustration} />
            </Box>
            <Text textAlign={textAlign} fontSize="xl" fontWeight={fontWeight ?? 'bold'} as="p" marginBottom="l">
              {quote}
            </Text>
            <Text fontSize="base" textAlign={textAlign} color="malm0">
              {signature}
            </Text>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default QuoteSection
