import { Box, Container } from 'atomic'
import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../../helpers/BuilderPropCleaner'
import { ThemeColors, ouTheme } from 'atomic/styles/theme.css'
import SpecialIllustration from 'atomic/components/atoms/illustrations/SpecialIllustrations'
import * as css from './CustomizableContainer.css'

type BackgroundOverlayItem = {
  image: string
  imagePosition?: string
  hideOnMobile?: boolean
}

type CustomizableContainerProps = {
  backgroundColor?: keyof ThemeColors
  secondColor?: keyof ThemeColors
  addWaves: boolean
  backgroundOverlay: BackgroundOverlayItem
  children: React.ReactNode | React.ReactNode[]
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  space?: keyof typeof ouTheme.spaces
  marginBottom?: keyof typeof ouTheme.spaces
} & BuilderProps

const CustomizableContainer: FC<CustomizableContainerProps> = ({
  backgroundColor,
  secondColor,
  addWaves = true,
  backgroundOverlay,
  children,
  direction,
  space,
  marginBottom,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Box marginBottom={marginBottom ?? 'none'}>
      <Box
        backgroundColor={backgroundColor ?? 'blåbär3'}
        style={
          backgroundOverlay
            ? {
                backgroundImage: `url(${backgroundOverlay?.image})`,
                backgroundPosition: backgroundOverlay?.imagePosition,
              }
            : {}
        }
        className={backgroundOverlay ? css.overlay : undefined}
        position={'relative'}
        {...cleanProps}
      >
        <Container display={'flex'} flexDirection={direction ?? 'row'} gap={space ?? 'none'} alignItems={'center'}>
          {children}
        </Container>
      </Box>
      {addWaves && (
        <Box position={'relative'} style={{ zIndex: -2 }} backgroundColor={secondColor ?? 'transparent'}>
          <SpecialIllustration name={'wavy-background'} color={backgroundColor ?? 'blåbär3'} className={css.waves} />
        </Box>
      )}
    </Box>
  )
}

export default CustomizableContainer
