import { Box, Text } from 'atomic'
import { ThemeColors, themeVars } from 'atomic/styles/theme.css'
import React, { FC, InputHTMLAttributes, useState } from 'react'
import * as css from './FormInput.css'
import { useSafeTranslation } from 'hooks/useSafeTranslation'

type FormInputProps = {
  translatedText: string
  className: string
  color?: keyof ThemeColors
} & InputHTMLAttributes<HTMLInputElement>

const FormInput: FC<FormInputProps> = ({ translatedText, className, color, onChange, ...props }) => {
  const { t } = useSafeTranslation()
  const [selected, setSelected] = useState(false)
  const [error, setError] = useState(false)

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (error) {
      setError(false)
    }
    onChange?.(event)
  }

  return (
    <Box position={'relative'}>
      {selected && (
        <Box position={'absolute'} style={{ top: -8, left: themeVars.spaces.xs }} backgroundColor={color ?? 'white'} paddingX={'xxxs'}>
          <Text fontWeight={'semibold'} fontSize={'xs'} color={error ? 'errorText' : 'malm0'} marginBottom={'none'}>
            {translatedText}
          </Text>
        </Box>
      )}
      <input
        placeholder={!selected ? translatedText : ''}
        onFocus={() => setSelected(true)}
        onBlur={() => setSelected(false)}
        onInvalid={() => setError(true)}
        onChange={handleOnChange}
        className={error ? `${className} ${css.error}` : `${className} ${css.formInput}`}
        {...props}
      />
      {/* 
      {required && (
        <Text
          position={'absolute'}
          style={{ top: '50%', right: themeVars.spaces.xs, transform: 'translate(-50%, -50%)' }}
          fontSize={'s'}
          fontWeight={selected ? 'semibold' : 'light'}
        >
          *
        </Text>
      )} */}
      {error && (
        <Text color={'errorText'} fontSize={'xs'} fontWeight={'normal'} marginTop={'xxxs'} marginBottom={'none'}>
          {t('form_validation_text_ohnoyoumissedaspot')}
        </Text>
      )}
    </Box>
  )
}

export default FormInput
