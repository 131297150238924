import dynamic from 'next/dynamic'
import React, { FC } from 'react'
import { ThemeColors } from 'atomic/styles/theme.css'

const Book = dynamic(() => import('./Book'), { ssr: false })
const Delivery = dynamic(() => import('./Delivery'), { ssr: false })
const Flower = dynamic(() => import('./Flower'), { ssr: false })
const Glasses = dynamic(() => import('./Glasses'), { ssr: false })
const Peace = dynamic(() => import('./Peace'), { ssr: false })
const Hearts = dynamic(() => import('./Hearts'), { ssr: false })
const Stars3 = dynamic(() => import('./Stars3'), { ssr: false })
const CreditCard = dynamic(() => import('./CreditCard'), { ssr: false })
const Carrot = dynamic(() => import('./Carrot'), { ssr: false })
const Chat = dynamic(() => import('./Chat'), { ssr: false })
const Clipboard = dynamic(() => import('./Clipboard'), { ssr: false })
const Cloud = dynamic(() => import('./Cloud'), { ssr: false })
const Cookie = dynamic(() => import('./Cookie'), { ssr: false })
const Cup = dynamic(() => import('./Cup'), { ssr: false })
const Discount25 = dynamic(() => import('./Discount25'), { ssr: false })
const GirlWithKey = dynamic(() => import('./GirlWithKey'), { ssr: false })
const HeartHug = dynamic(() => import('./HeartHug'), { ssr: false })
const GirlWithShoppingbag = dynamic(() => import('./GirlWithShoppingbag'), { ssr: false })
const Arms2 = dynamic(() => import('./Arms2'), { ssr: false })
const GirlEating = dynamic(() => import('./GirlEating'), { ssr: false })
const QuotationMarks = dynamic(() => import('./QuotationMarks'), { ssr: false })
const PeopleOnSkateboard = dynamic(() => import('./PeopleOnSkateboard'), { ssr: false })
const PeopleSharing = dynamic(() => import('./PeopleSharing'), { ssr: false })
const GuyMeditating = dynamic(() => import('./GuyMeditating'), { ssr: false })
const Gift = dynamic(() => import('./Gift'), { ssr: false })
const Gift2 = dynamic(() => import('./Gift2'), { ssr: false })
const GiftPile = dynamic(() => import('./GiftPile'), { ssr: false })
const GiftPile2 = dynamic(() => import('./GiftPile2'), { ssr: false })
const Hand4 = dynamic(() => import('./Hand4'), { ssr: false })
const RockOn = dynamic(() => import('./RockOn'), { ssr: false })
const Hand = dynamic(() => import('./Hand'), { ssr: false })
const Hand2 = dynamic(() => import('./Hand2'), { ssr: false })
const Hand3 = dynamic(() => import('./Hand3'), { ssr: false })
const Heart = dynamic(() => import('./Heart'), { ssr: false })
const Icecream = dynamic(() => import('./Icecream'), { ssr: false })
const Key = dynamic(() => import('./Key'), { ssr: false })
const Lamp = dynamic(() => import('./Lamp'), { ssr: false })
const Lemon = dynamic(() => import('./Lemon'), { ssr: false })
const Mail = dynamic(() => import('./Mail'), { ssr: false })
const Megaphone = dynamic(() => import('./Megaphone'), { ssr: false })
const Moon = dynamic(() => import('./Moon'), { ssr: false })
const Music = dynamic(() => import('./Music'), { ssr: false })
const Pen = dynamic(() => import('./Pen'), { ssr: false })
const Camera = dynamic(() => import('./Camera'), { ssr: false })
const Jar = dynamic(() => import('./Jar'), { ssr: false })
const Plant = dynamic(() => import('./Plant'), { ssr: false })
const Prints = dynamic(() => import('./Prints'), { ssr: false })
const Lock = dynamic(() => import('./Lock'), { ssr: false })
const Santa = dynamic(() => import('./Santa'), { ssr: false })
const Satellite = dynamic(() => import('./Satellite'), { ssr: false })
const Sausage = dynamic(() => import('./Sausage'), { ssr: false })
const Save = dynamic(() => import('./Save'), { ssr: false })
const Computer = dynamic(() => import('./Computer'), { ssr: false })
const Tools = dynamic(() => import('./Tools'), { ssr: false })
const ShoppingBag = dynamic(() => import('./ShoppingBag'), { ssr: false })
const RollerSkate = dynamic(() => import('./RollerSkate'), { ssr: false })
const Star = dynamic(() => import('./Star'), { ssr: false })
const Stars1 = dynamic(() => import('./Stars1'), { ssr: false })
const Stars2 = dynamic(() => import('./Stars2'), { ssr: false })
const Clock = dynamic(() => import('./Clock'), { ssr: false })
const Cake = dynamic(() => import('./Cake'), { ssr: false })
const Cardboard = dynamic(() => import('./Cardboard'), { ssr: false })
const Scissor = dynamic(() => import('./Scissor'), { ssr: false })
const Stars4 = dynamic(() => import('./Stars4'), { ssr: false })
const Strawberry = dynamic(() => import('./Strawberry'), { ssr: false })
const Bell = dynamic(() => import('./Bell'), { ssr: false })
const Arms = dynamic(() => import('./Arms'), { ssr: false })
const Sun = dynamic(() => import('./Sun'), { ssr: false })
const Tea = dynamic(() => import('./Tea'), { ssr: false })
const Diamond = dynamic(() => import('./Diamond'), { ssr: false })
const Ufo = dynamic(() => import('./Ufo'), { ssr: false })
const Highfive = dynamic(() => import('./Highfive'), { ssr: false })
const Wifi = dynamic(() => import('./Wifi'), { ssr: false })
const Write = dynamic(() => import('./Write'), { ssr: false })
const EarthGirl = dynamic(() => import('./EarthGirl'), { ssr: false })
const Arrow01 = dynamic(() => import('./Arrow01'), { ssr: false })
const Arrow02 = dynamic(() => import('./Arrow02'), { ssr: false })
const Arrow03 = dynamic(() => import('./Arrow03'), { ssr: false })
const Arrow04 = dynamic(() => import('./Arrow04'), { ssr: false })
const Arrow05 = dynamic(() => import('./Arrow05'), { ssr: false })
const Arrow06 = dynamic(() => import('./Arrow06'), { ssr: false })
const Arrow07 = dynamic(() => import('./Arrow07'), { ssr: false })
const Arrow08 = dynamic(() => import('./Arrow08'), { ssr: false })
const Arrow09 = dynamic(() => import('./Arrow09'), { ssr: false })
const Arrow10 = dynamic(() => import('./Arrow10'), { ssr: false })
const Arrow11 = dynamic(() => import('./Arrow11'), { ssr: false })
const Kludd01 = dynamic(() => import('./Kludd01'), { ssr: false })
const Kludd02 = dynamic(() => import('./Kludd02'), { ssr: false })
const Kludd03 = dynamic(() => import('./Kludd03'), { ssr: false })
const Kludd04 = dynamic(() => import('./Kludd04'), { ssr: false })
const Kludd05 = dynamic(() => import('./Kludd05'), { ssr: false })
const Kludd06 = dynamic(() => import('./Kludd06'), { ssr: false })
const Kludd07 = dynamic(() => import('./Kludd07'), { ssr: false })
const Kludd08 = dynamic(() => import('./Kludd08'), { ssr: false })
const Kludd09 = dynamic(() => import('./Kludd09'), { ssr: false })
const Kludd10 = dynamic(() => import('./Kludd10'), { ssr: false })
const Kludd11 = dynamic(() => import('./Kludd11'), { ssr: false })
const Kludd12 = dynamic(() => import('./Kludd12'), { ssr: false })
const Kludd13 = dynamic(() => import('./Kludd13'), { ssr: false })
const Kludd14 = dynamic(() => import('./Kludd14'), { ssr: false })
const Kludd15 = dynamic(() => import('./Kludd15'), { ssr: false })
const Kludd16 = dynamic(() => import('./Kludd16'), { ssr: false })
const Kludd17 = dynamic(() => import('./Kludd17'), { ssr: false })
const Kludd18 = dynamic(() => import('./Kludd18'), { ssr: false })
const Kludd19 = dynamic(() => import('./Kludd19'), { ssr: false })
const SpeechBubble = dynamic(() => import('./SpeechBubble'), { ssr: false })
const TwoPeople = dynamic(() => import('./TwoPeople'), { ssr: false })
const SatisfactionHand = dynamic(() => import('./SatisfactionHand'), { ssr: false })
const Underline = dynamic(() => import('./Underline'), { ssr: false })
const Discount25Off = dynamic(() => import('./Discount25Off'), { ssr: false })
const BookWithKludd = dynamic(() => import('./BookWithKludd'), { ssr: false })
const LampWithKludd = dynamic(() => import('./LampWithKludd'), { ssr: false })
const Money = dynamic(() => import('./Money'), { ssr: false })
const Discount40 = dynamic(() => import('./Discount40'), { ssr: false })
const Drawing21 = dynamic(() => import('./Drawing21'), { ssr: false })
const Drawing4 = dynamic(() => import('./Drawing4'), { ssr: false })
const OnceUponLogo = dynamic(() => import('./OnceUponLogo'), { ssr: false })
const Holiday2 = dynamic(() => import('./Holiday2'), { ssr: false })
const Foot = dynamic(() => import('./Foot'), { ssr: false })
const Popcorn = dynamic(() => import('./Popcorn'), { ssr: false })
const Snow3 = dynamic(() => import('./Snow3'), { ssr: false })

export const illustrationTypes = [
  'arms',
  'arms-2',
  'arrow-1',
  'arrow-2',
  'arrow-3',
  'arrow-4',
  'arrow-5',
  'arrow-6',
  'arrow-7',
  'arrow-8',
  'arrow-9',
  'arrow-10',
  'arrow-11',
  'bell',
  'book',
  'book-with-kludd',
  'cake',
  'camera',
  'cardboard',
  'carrot',
  'chat',
  'clipboard',
  'clock',
  'cloud',
  'computer',
  'cookie',
  'credit-card',
  'cup',
  'delivery',
  'diamond',
  'discount-25',
  'discount-40',
  'discount-25-off',
  'drawing-21',
  'drawing-4',
  'earth-girl',
  'flower',
  'gift',
  'gift-2',
  'gift-pile',
  'gift-pile-2',
  'girl-eating',
  'girl-with-key',
  'girl-with-shoppingbag',
  'glasses',
  'guy-meditating',
  'hand',
  'hand-2',
  'hand-3',
  'hand-4',
  'heart',
  'hearts',
  'heart-hug',
  'highfive',
  'icecream',
  'jar',
  'key',
  'kludd-1',
  'kludd-2',
  'kludd-3',
  'kludd-4',
  'kludd-5',
  'kludd-6',
  'kludd-7',
  'kludd-8',
  'kludd-9',
  'kludd-10',
  'kludd-11',
  'kludd-12',
  'kludd-13',
  'kludd-14',
  'kludd-15',
  'kludd-16',
  'kludd-17',
  'kludd-18',
  'kludd-19',
  'lamp',
  'lamp-with-kludd',
  'lemon',
  'lock',
  'mail',
  'megaphone',
  'money',
  'moon',
  'music',
  'onceupon-logo',
  'peace',
  'pen',
  'people-on-skateboard',
  'people-sharing',
  'plant',
  'prints',
  'quotation-marks',
  'rock-on',
  'roller-skate',
  'santa',
  'satellite',
  'satisfaction-hand',
  'sausage',
  'save',
  'scissor',
  'shopping-bag',
  'speech-bubble',
  'star',
  'stars-1',
  'stars-2',
  'stars-3',
  'stars-4',
  'strawberry',
  'sun',
  'tea',
  'tools',
  'two-people',
  'ufo',
  'underline',
  'wifi',
  'write',
  'holiday-2',
  'foot',
  'popcorn',
  'snow-3',
] as const

export type IllustrationType = (typeof illustrationTypes)[number]

export type IllustrationPropTypes = {
  name: IllustrationType
  color?: keyof ThemeColors
  width?: string | number
  height?: string | number
  className?: string
  style?: React.CSSProperties
}

const Illustrations: FC<IllustrationPropTypes> = ({ name, color, className, width, height, style }) => {
  switch (name) {
    case 'arms':
      return <Arms className={className} color={color} width={width} height={height} />
    case 'arms-2':
      return <Arms2 className={className} color={color} width={width} height={height} />
    case 'arrow-1':
      return <Arrow01 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-2':
      return <Arrow02 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-3':
      return <Arrow03 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-4':
      return <Arrow04 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-5':
      return <Arrow05 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-6':
      return <Arrow06 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-7':
      return <Arrow07 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-8':
      return <Arrow08 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-9':
      return <Arrow09 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-10':
      return <Arrow10 className={className} color={color} width={width} height={height} style={style} />
    case 'arrow-11':
      return <Arrow11 className={className} color={color} width={width} height={height} style={style} />
    case 'bell':
      return <Bell className={className} color={color} width={width} height={height} />
    case 'book':
      return <Book className={className} color={color} width={width} height={height} />
    case 'book-with-kludd':
      return <BookWithKludd className={className} color={color} width={width} height={height} />
    case 'cake':
      return <Cake className={className} color={color} width={width} height={height} />
    case 'camera':
      return <Camera className={className} color={color} width={width} height={height} />
    case 'cardboard':
      return <Cardboard className={className} color={color} width={width} height={height} />
    case 'carrot':
      return <Carrot className={className} color={color} width={width} height={height} />
    case 'chat':
      return <Chat className={className} color={color} width={width} height={height} />
    case 'clipboard':
      return <Clipboard className={className} color={color} width={width} height={height} />
    case 'clock':
      return <Clock className={className} color={color} width={width} height={height} />
    case 'cloud':
      return <Cloud className={className} color={color} width={width} height={height} />
    case 'computer':
      return <Computer className={className} color={color} width={width} height={height} />
    case 'cookie':
      return <Cookie className={className} color={color} width={width} height={height} />
    case 'credit-card':
      return <CreditCard className={className} color={color} width={width} height={height} />
    case 'cup':
      return <Cup className={className} color={color} width={width} height={height} />
    case 'delivery':
      return <Delivery className={className} color={color} width={width} height={height} />
    case 'diamond':
      return <Diamond className={className} color={color} width={width} height={height} />
    case 'discount-25':
      return <Discount25 className={className} color={color} width={width} height={height} />
    case 'discount-40':
      return <Discount40 className={className} color={color} width={width} height={height} />
    case 'discount-25-off':
      return <Discount25Off className={className} color={color} width={width} height={height} />
    case 'drawing-21':
      return <Drawing21 className={className} color={color} width={width} height={height} />
    case 'drawing-4':
      return <Drawing4 className={className} color={color} width={width} height={height} />
    case 'earth-girl':
      return <EarthGirl className={className} color={color} width={width} height={height} />
    case 'flower':
      return <Flower className={className} color={color} width={width} height={height} />
    case 'gift':
      return <Gift className={className} color={color} width={width} height={height} />
    case 'gift-2':
      return <Gift2 className={className} color={color} width={width} height={height} />
    case 'gift-pile':
      return <GiftPile className={className} color={color} width={width} height={height} />
    case 'gift-pile-2':
      return <GiftPile2 className={className} color={color} width={width} height={height} />
    case 'girl-eating':
      return <GirlEating className={className} color={color} width={width} height={height} />
    case 'girl-with-key':
      return <GirlWithKey className={className} color={color} width={width} height={height} />
    case 'girl-with-shoppingbag':
      return <GirlWithShoppingbag className={className} color={color} width={width} height={height} />
    case 'glasses':
      return <Glasses className={className} color={color} width={width} height={height} />
    case 'guy-meditating':
      return <GuyMeditating className={className} color={color} width={width} height={height} />
    case 'hand':
      return <Hand className={className} color={color} width={width} height={height} />
    case 'hand-2':
      return <Hand2 className={className} color={color} width={width} height={height} />
    case 'hand-3':
      return <Hand3 className={className} color={color} width={width} height={height} />
    case 'hand-4':
      return <Hand4 className={className} color={color} width={width} height={height} />
    case 'heart':
      return <Heart className={className} color={color} width={width} height={height} />
    case 'hearts':
      return <Hearts className={className} color={color} width={width} height={height} />
    case 'heart-hug':
      return <HeartHug className={className} color={color} width={width} height={height} />
    case 'highfive':
      return <Highfive className={className} color={color} width={width} height={height} />
    case 'icecream':
      return <Icecream className={className} color={color} width={width} height={height} />
    case 'jar':
      return <Jar className={className} color={color} width={width} height={height} />
    case 'key':
      return <Key className={className} color={color} width={width} height={height} />
    case 'kludd-1':
      return <Kludd01 className={className} color={color} width={width} height={height} />
    case 'kludd-2':
      return <Kludd02 className={className} color={color} width={width} height={height} />
    case 'kludd-3':
      return <Kludd03 className={className} color={color} width={width} height={height} />
    case 'kludd-4':
      return <Kludd04 className={className} color={color} width={width} height={height} />
    case 'kludd-5':
      return <Kludd05 className={className} color={color} width={width} height={height} />
    case 'kludd-6':
      return <Kludd06 className={className} color={color} width={width} height={height} />
    case 'kludd-7':
      return <Kludd07 className={className} color={color} width={width} height={height} />
    case 'kludd-8':
      return <Kludd08 className={className} color={color} width={width} height={height} />
    case 'kludd-9':
      return <Kludd09 className={className} color={color} width={width} height={height} />
    case 'kludd-10':
      return <Kludd10 className={className} color={color} width={width} height={height} />
    case 'kludd-11':
      return <Kludd11 className={className} color={color} width={width} height={height} />
    case 'kludd-12':
      return <Kludd12 className={className} color={color} width={width} height={height} />
    case 'kludd-13':
      return <Kludd13 className={className} color={color} width={width} height={height} />
    case 'kludd-14':
      return <Kludd14 className={className} color={color} width={width} height={height} />
    case 'kludd-15':
      return <Kludd15 className={className} color={color} width={width} height={height} />
    case 'kludd-16':
      return <Kludd16 className={className} color={color} width={width} height={height} />
    case 'kludd-17':
      return <Kludd17 className={className} color={color} width={width} height={height} />
    case 'kludd-18':
      return <Kludd18 className={className} color={color} width={width} height={height} />
    case 'kludd-19':
      return <Kludd19 className={className} color={color} width={width} height={height} />
    case 'lamp':
      return <Lamp className={className} color={color} width={width} height={height} />
    case 'lamp-with-kludd':
      return <LampWithKludd className={className} color={color} width={width} height={height} />
    case 'lemon':
      return <Lemon className={className} color={color} width={width} height={height} />
    case 'lock':
      return <Lock className={className} color={color} width={width} height={height} />
    case 'mail':
      return <Mail className={className} color={color} width={width} height={height} />
    case 'megaphone':
      return <Megaphone className={className} color={color} width={width} height={height} />
    case 'money':
      return <Money className={className} color={color} width={width} height={height} />
    case 'moon':
      return <Moon className={className} color={color} width={width} height={height} />
    case 'music':
      return <Music className={className} color={color} width={width} height={height} />
    case 'onceupon-logo':
      return <OnceUponLogo className={className} color={color} width={width} height={height} />
    case 'peace':
      return <Peace className={className} color={color} width={width} height={height} />
    case 'pen':
      return <Pen className={className} color={color} width={width} height={height} />
    case 'people-on-skateboard':
      return <PeopleOnSkateboard className={className} color={color} width={width} height={height} />
    case 'people-sharing':
      return <PeopleSharing className={className} color={color} width={width} height={height} />
    case 'plant':
      return <Plant className={className} color={color} width={width} height={height} />
    case 'prints':
      return <Prints className={className} color={color} width={width} height={height} />
    case 'quotation-marks':
      return <QuotationMarks className={className} color={color} width={width} height={height} />
    case 'rock-on':
      return <RockOn className={className} color={color} width={width} height={height} />
    case 'roller-skate':
      return <RollerSkate className={className} color={color} width={width} height={height} />
    case 'santa':
      return <Santa className={className} color={color} width={width} height={height} />
    case 'satellite':
      return <Satellite className={className} color={color} width={width} height={height} />
    case 'satisfaction-hand':
      return <SatisfactionHand className={className} color={color} width={width} height={height} />
    case 'sausage':
      return <Sausage className={className} color={color} width={width} height={height} />
    case 'save':
      return <Save className={className} color={color} width={width} height={height} />
    case 'scissor':
      return <Scissor className={className} color={color} width={width} height={height} />
    case 'shopping-bag':
      return <ShoppingBag className={className} color={color} width={width} height={height} />
    case 'speech-bubble':
      return <SpeechBubble className={className} color={color} width={width} height={height} />
    case 'star':
      return <Star className={className} color={color} width={width} height={height} />
    case 'stars-1':
      return <Stars1 className={className} color={color} width={width} height={height} />
    case 'stars-2':
      return <Stars2 className={className} color={color} width={width} height={height} />
    case 'stars-3':
      return <Stars3 className={className} color={color} width={width} height={height} />
    case 'stars-4':
      return <Stars4 className={className} color={color} width={width} height={height} />
    case 'strawberry':
      return <Strawberry className={className} color={color} width={width} height={height} />
    case 'sun':
      return <Sun className={className} color={color} width={width} height={height} />
    case 'tea':
      return <Tea className={className} color={color} width={width} height={height} />
    case 'tools':
      return <Tools className={className} color={color} width={width} height={height} />
    case 'two-people':
      return <TwoPeople className={className} color={color} width={width} height={height} />
    case 'ufo':
      return <Ufo className={className} color={color} width={width} height={height} />
    case 'underline':
      return <Underline className={className} color={color} width={width} height={height} />
    case 'wifi':
      return <Wifi className={className} color={color} width={width} height={height} />
    case 'write':
      return <Write className={className} color={color} width={width} height={height} />
    case 'holiday-2':
      return <Holiday2 className={className} color={color} width={width} height={height} />
    case 'foot':
      return <Foot className={className} color={color} width={width} height={height} />
    case 'popcorn':
      return <Popcorn className={className} color={color} width={width} height={height} />
    case 'snow-3':
      return <Snow3 className={className} color={color} width={width} height={height} />
    default:
      return null
  }
}

export default Illustrations
