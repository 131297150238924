import React, { FC } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { FormFields } from '../FormFields'
import OrderNumber from '../form_fields/OrderNumber'
import StreetAddress from '../form_fields/StreetAddress'
import AddressLine2 from '../form_fields/AddressLine2'
import Postcode from '../form_fields/Postcode'
import City from '../form_fields/City'
import Country from '../form_fields/Country'
import StateProvinceRegion from '../form_fields/StateProvinceRegion'
import ReceiverFirstname from '../form_fields/ReceiverFirstname'
import ReceiverSurname from '../form_fields/ReceiverSurname'
import ReceiverPhoneNumber from '../form_fields/ReceiverPhoneNumber'
/* import Information from '../form_fields/Information' */
import { useSafeTranslation } from 'hooks/useSafeTranslation'

type PropTypes = {
  errors: { [key: string]: string }
  register: UseFormRegister<FieldValues>
}

const ChangeDeliveryAddress: FC<PropTypes> = ({ errors, register }) => {
  const { t } = useSafeTranslation()
  return (
    <>
      <OrderNumber
        errors={errors}
        register={register(FormFields.OrderNumber.id, {
          required: t('form_validation_text_ohnoyoumissedaspot'),
          pattern: {
            value: /^([O|o][U|u])(\d{3,4})-(.{4,6})$/,
            message: t('form_validation_text_ohnoyoumissedaspot'),
          },
        })}
        required={true}
      />
      <ReceiverFirstname
        errors={errors}
        register={register(FormFields.ReceiverFirstname.id, {
          required: false,
        })}
      />
      <ReceiverSurname
        errors={errors}
        register={register(FormFields.ReceiverSurname.id, {
          required: false,
        })}
      />
      <ReceiverPhoneNumber
        errors={errors}
        register={register(FormFields.ReceiverPhoneNumber.id, {
          required: false,
        })}
      />
      <StreetAddress
        errors={errors}
        register={register(FormFields.StreetAddress.id, {
          required: t('form_validation_text_ohnoyoumissedaspot'),
        })}
        required={true}
      />
      <AddressLine2
        errors={errors}
        register={register(FormFields.AddressLine2.id, {
          required: false,
        })}
      />
      <Postcode
        errors={errors}
        register={register(FormFields.Postcode.id, {
          required: t('form_validation_text_ohnoyoumissedaspot'),
        })}
        required={true}
      />
      <City
        errors={errors}
        register={register(FormFields.City.id, {
          required: t('form_validation_text_ohnoyoumissedaspot'),
        })}
        required={true}
      />
      <Country
        errors={errors}
        register={register(FormFields.Country.id, {
          required: t('form_validation_text_ohnoyoumissedaspot'),
        })}
        required={true}
      />
      <StateProvinceRegion
        errors={errors}
        register={register(FormFields.StateProvinceRegion.id, {
          required: false,
        })}
      />
      {/* <Information
        header={t('form_information_header_1')}
        text={t('form_information_changeaddress_text')} //"If you want to cancel your order, please provide us with the order number. We will then cancel the order for you."
        illustrationType="lamp-with-kludd"
      /> */}
    </>
  )
}

export default ChangeDeliveryAddress
