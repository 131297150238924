import InternalLink from 'components/internallink/InternalLink'
import { useRouter } from 'next/router'
import { FC } from 'react'
import Slider from 'react-slick'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from 'atomic'
import { storyblokEditable } from '@storyblok/react'

import { useLink } from '../../../context/LinkContext'
import isVideo from '../../../utils/is-video'
import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './BlogPostList.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const BlogPostList: FC<PropTypes> = ({ blok }) => {
  const router = useRouter()
  const { getLocalePath } = useLink()

  const getTranslatedLocalePath = (fullSlug: string, locale: string): string => {
    const slugWithoutLocale = fullSlug.replace(`${locale}/`, '')
    const localePath = getLocalePath(slugWithoutLocale, locale)
    return localePath
  }

  const asset = (blogpost: StoryblokContent): JSX.Element => {
    const filename = blogpost.content?.puff_image?.filename ?? blogpost.content?.image?.filename
    if (isVideo(filename)) {
      return (
        <video autoPlay loop muted playsInline={true}>
          <source src={filename} />
        </video>
      )
    } else {
      return (
        <StoryblokImage
          src={blogpost.content?.puff_image?.filename ?? blogpost.content?.image?.filename}
          width={640}
          height={640}
          quality={100}
          alt={blogpost.content?.puff_image?.name ?? blogpost.content?.image?.name}
          style={{ objectPosition: blogpost.content?.alignment ?? 'center' }}
        />
      )
    }
  }

  const renderBlogPostList = (blok: StoryblokContent): JSX.Element => {
    const blogpostlist = blok.blogposts
    return blogpostlist?.map((blogpost) => (
      <div key={blogpost.id} {...storyblokEditable(blogpost)}>
        <div className={`${styles.blogPostContainer}`}>
          <div className={`${styles.column} ${styles.blogPostImage}`}>{asset(blogpost)}</div>
          <div className={`${styles.column} ${styles.blogPostText}`}>
            <div className={styles.columnContentContainer}>
              <div className={styles.signature}>Once Upon Stories</div>
              <div className={styles.title}>
                <h2>{blogpost.content?.puff_title ?? blogpost.content?.title}</h2>
              </div>
              <div className={styles.description}>{blogpost.content?.puff_text ?? blogpost.content?.intro}</div>
              <div className={styles.buttonContainer}>
                <InternalLink href={getTranslatedLocalePath(blogpost.full_slug, router.locale)} passHref>
                  <Button variant="secondary">{blok.go_to_blogpost_text}</Button>
                </InternalLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 15000,
  }

  // only show blogposts if swedish, english, german or dutch locale
  const available_languages: string[] = ['en-gb', 'en-us', 'en-au', 'nl-nl', 'de-de', 'sv-se', 'it-it', 'fr-fr', 'es-es']
  const isAllowed: boolean = available_languages?.includes(router.locale)
  if (!isAllowed) {
    return <div></div>
  }

  if (blok.blogposts?.length === 0) {
    // fix/hack for known issue with infinite: true + fade: true, https://github.com/akiran/react-slick/issues/1343
    return <Slider key="0" />
  }

  return (
    <section className={styles.sectionContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.blogPostListContainer}>
          {/* https://react-slick.neostack.com/docs/get-started */}
          <Slider {...sliderSettings}>{renderBlogPostList(blok)}</Slider>
        </div>
      </div>
    </section>
  )
}

export default BlogPostList
