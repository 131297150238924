import React, { Dispatch, SetStateAction, useEffect /* , useRef */ } from 'react'
import { FormFields } from '../FormFields'
import Image from 'next/image'
import AddImage from 'components/icons/AddImage'
import { SubmitMessage, SubmitMessageStatus } from 'components/submitmessage/SubmitMessage'
import log from 'utils/logger'
import Compressor from 'compressorjs'
import * as styles from '../FormCss.css'
import { FieldValues, UseFormClearErrors, UseFormRegister, UseFormSetError } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { Box, Text } from 'atomic'

export type ScreenshotsDependencies = {
  setFiles?: Dispatch<SetStateAction<{ [key: string]: File }>>
  getFiles?: { [key: string]: File }
  setSubmitMessage: Dispatch<SetStateAction<SubmitMessage | null>>
  setError: UseFormSetError<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  required?: boolean
}

interface PropTypes {
  errors: { [key: string]: string }
  register: UseFormRegister<FieldValues>
  screenshotsDependencies: ScreenshotsDependencies
}

const Screenshots: React.FC<PropTypes> = ({ errors, register, screenshotsDependencies }) => {
  const { setFiles, getFiles, setSubmitMessage, setError, clearErrors, required } = screenshotsDependencies
  //const firstUpdate = useRef(true)
  const { t } = useSafeTranslation()
  const validationMessage = t('form_validation_text_ohnoyoumissedaspot')
  const description = t('Form_images_upload')

  if (required) {
    useEffect(() => {
      //console.log('getFiles', getFiles)
      /* if (firstUpdate.current) {
        firstUpdate.current = false
        return
      } */

      if (!getFiles || Object.keys(getFiles).length === 0) {
        setError(FormFields.Screenshots.id, {
          type: 'manual',
          message: validationMessage,
        })
      } else {
        clearErrors(FormFields.Screenshots.id)
      }
    }, [getFiles])
  }

  const handleSelectedFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, files } = e.target
    if (files.length > 0) {
      const file = files[0]
      const tenMbInBytes = 10485760
      if (file.size > tenMbInBytes) {
        log.error(`Uploaded file is too large, ${file.size}`)
        setSubmitMessage({ status: SubmitMessageStatus.Negative, text: validationMessage })
        return
      }
      setSubmitMessage(null)
      new Compressor(file, {
        quality: 0.5,
        success(result: File) {
          setFiles((prevState) => ({
            ...prevState,
            [name]: result,
          }))
        },
        error(err) {
          log.error(err.message)
        },
      })
    }
  }

  const deleteFile = (id: string): void => {
    const newFiles = { ...getFiles }
    if (newFiles[id]) {
      delete newFiles[id]
      setFiles(newFiles)
    }
  }

  const getUrlForFile = (id: string): string | null => {
    const file = getFiles[id]
    if (file) {
      return URL.createObjectURL(file)
    } else {
      return null
    }
  }

  const getNameForFile = (id: string): string | null => {
    const file = getFiles[id]
    if (file) {
      return file.name
    } else {
      return null
    }
  }

  return (
    <Box display="flex" flexDirection="column" className={`${styles.fullWidth}`} marginBottom="xs">
      <label className={`${styles.labelElementForScreenshots} ${required && styles.required}`} htmlFor={FormFields.Screenshots.id}>
        {t('form_screenshots_label')}
      </label>
      {description && <Text fontSize="s">{description}</Text>}
      <Box display="flex" gap="xl" marginTop="none">
        {Array.from({ length: 3 }, (_, i) => {
          const id = `${FormFields.Screenshots.id}_${i}`
          const uploadedImageFile = getUrlForFile(id)
          if (uploadedImageFile) {
            return (
              <Box key={i} className={styles.imagePreviewContainer}>
                <Text as="span" style={{ paddingTop: '90%' }} />
                <button onClick={() => deleteFile(id)} className={styles.closeButton}>
                  &#215;
                </button>
                <Image src={uploadedImageFile} fill style={{ objectFit: 'contain' }} alt="preview" />
                <Text className={styles.fileName}>{getNameForFile(id)}</Text>
              </Box>
            )
          }

          return (
            <label key={i} className={`${errors[id] && styles.invalid} ${styles.customFileLabel}`}>
              <input
                id={id}
                name={id}
                type="file"
                placeholder=""
                accept="image/*"
                {...register(id)}
                onChange={(e) => {
                  handleSelectedFile(e)
                }}
                style={{ display: 'none' }}
              />
              <Box className={styles.customFileButton}>
                <AddImage className={styles.addImage} />
              </Box>
            </label>
          )
        })}
      </Box>
      <ErrorMessage
        errors={errors}
        name={FormFields.Screenshots.id}
        render={({ message }) => (
          <Text fontSize="xs" fontWeight="normal" color="errorText">
            {message}
          </Text>
        )}
      />
    </Box>
  )
}

export default Screenshots
