import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from 'atomic'
import { storyblokEditable } from '@storyblok/react'

import { useModalState } from '../../../context/ModalStateContext'

import styles from './GetAppButton.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const GetAppButton: FC<PropTypes> = ({ blok }) => {
  const { setShowGetAppModal } = useModalState()

  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <div>
        <Button onClick={() => setShowGetAppModal(true)}>{blok.button_title}</Button>
      </div>
    </section>
  )
}

export default GetAppButton
