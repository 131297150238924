import { Box } from 'atomic'
import { IllustrationBaseProps } from 'atomic/components/atoms/illustrations/Base'
import React, { FC } from 'react'

const WavyBackground: FC<IllustrationBaseProps> = ({ width = '100%', height = '100%', ...props }) => {
  return (
    <Box {...props}>
      <svg width={width} height={height} viewBox="0 0 2240 141" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.498047 0.359375V73.3244C93.7899 73.3244 93.7899 140.359 187.082 140.359C280.374 140.359 280.374 73.3244 373.665 73.3244C466.957 73.3244 466.957 140.359 560.237 140.359C653.517 140.359 653.529 73.3244 746.821 73.3244C840.113 73.3244 840.113 140.359 933.404 140.359C1026.7 140.359 1026.7 73.3244 1119.99 73.3244C1213.28 73.3244 1213.28 140.359 1306.57 140.359C1399.86 140.359 1399.86 73.3244 1493.16 73.3244C1586.45 73.3244 1586.45 140.359 1679.74 140.359C1773.03 140.359 1773.03 73.3244 1866.32 73.3244C1959.61 73.3244 1959.61 140.359 2052.91 140.359C2146.2 140.359 2146.2 73.3244 2239.5 73.3244V0.359375H0.498047Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  )
}

export default WavyBackground
