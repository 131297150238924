import Illustration, { IllustrationType } from 'atomic/components/atoms/illustrations/Illustration'
import { CSSProperties, FC } from 'react'
import { useSnapCarousel } from 'react-snap-carousel'
import { Button, Text } from 'atomic'

import * as css from './CardSlider.css'
import { Trans } from 'next-i18next'
//import { LocaleKey } from 'types/LokalizeKeys'

import style from './Cardslider.module.scss'
import InternalLink from 'components/internallink/InternalLink'
import { useLink } from 'context/LinkContext'
import { useRouter } from 'next/router'
import { Link } from 'components/link/Link'
import useAppLinks from 'hooks/useAppLinks'
import { useSafeTranslation } from 'hooks/useSafeTranslation'

export interface CardSliderProps<T> {
  readonly items: T[]
  readonly renderItem: (props: ImageSliderRenderItemProps<T>) => React.ReactElement<CardSliderItemProps>
}

export interface ImageSliderRenderItemProps<T> {
  readonly item: T
  readonly isSnapPoint: boolean
}

export type CardContentProps = {
  title: string
  subtitle?: string //LocaleKey
  invertedColors?: boolean
  illustration: IllustrationType
  illustrationsWidth?: string | number
  fromDate?: string
  toDate?: string
  backgroundColor: string
  action?: { btnText: string; onClick: () => void }
  link?: string
  linkId?: string
  uid: string
  useGetTheAppLink?: boolean
}

const styles = {
  itemSnapPoint: {
    scrollSnapAlign: 'start',
  },
  paginationButtonActive: { opacity: 0.3 },
} satisfies Record<string, CSSProperties>

export const CardContent: FC<CardContentProps> = (props) => {
  const styles = {
    container: {
      backgroundColor: props.backgroundColor,
    },
  } satisfies Record<string, CSSProperties>

  const { getLocalePath } = useLink()
  const router = useRouter()
  const { activeOsUrl } = useAppLinks()
  const { t } = useSafeTranslation()

  return (
    <>
      {props.link ? (
        <InternalLink href={getLocalePath(props.link, router.locale)} id={props.linkId}>
          <div className={css.cardContent} style={styles.container}>
            <Illustration
              className={style.illustrationImage}
              color={props.invertedColors ? 'white' : 'malm0'}
              name={props.illustration}
              width={props.illustrationsWidth ?? '100%'}
            />
            <Text
              fontWeight="bold"
              fontSize="base"
              textAlign="center"
              marginBottom="xs"
              marginTop="s"
              color={props.invertedColors ? 'white' : 'malm0'}
            >
              {props.title}
            </Text>
            {props.subtitle ? (
              <Text
                paddingLeft="m"
                paddingRight="m"
                textAlign="center"
                fontSize="s"
                className={style.text}
                color={props.invertedColors ? 'white' : 'malm0'}
              >
                <Trans key={props.subtitle} i18nKey={props.subtitle} />
              </Text>
            ) : null}

            {props.action ? (
              <Button variant="primary" onClick={props.action.onClick}>
                {props.action.btnText}
              </Button>
            ) : null}
          </div>
        </InternalLink>
      ) : props.useGetTheAppLink ? (
        <div className={css.cardContent} style={styles.container}>
          <Illustration
            className={style.illustrationImage}
            color={props.invertedColors ? 'white' : 'malm0'}
            name={props.illustration}
            width={props.illustrationsWidth ?? '100%'}
          />
          <Text fontWeight="bold" fontSize="base" textAlign="center" marginBottom="xs" marginTop="s" color={props.invertedColors ? 'white' : 'malm0'}>
            {props.title}
          </Text>
          {props.subtitle ? (
            <Text
              paddingLeft="m"
              paddingRight="m"
              textAlign="center"
              fontSize="s"
              className={style.text}
              color={props.invertedColors ? 'white' : 'malm0'}
            >
              <Trans key={props.subtitle} i18nKey={props.subtitle} />
            </Text>
          ) : null}

          <Link href={activeOsUrl} target="_blank">
            <Button variant="primary">{t('msg_sheet_prints_web_download_app_caption')}</Button>
          </Link>

          {props.action ? (
            <Button variant="primary" onClick={props.action.onClick}>
              {props.action.btnText}
            </Button>
          ) : null}
        </div>
      ) : (
        <div className={css.cardContent} style={styles.container}>
          <Illustration
            className={style.illustrationImage}
            color={props.invertedColors ? 'white' : 'malm0'}
            name={props.illustration}
            width={props.illustrationsWidth ?? '100%'}
          />
          <Text fontWeight="bold" fontSize="base" textAlign="center" marginBottom="xs" marginTop="s" color={props.invertedColors ? 'white' : 'malm0'}>
            {props.title}
          </Text>
          {props.subtitle ? (
            <Text
              paddingLeft="m"
              paddingRight="m"
              textAlign="center"
              fontSize="s"
              className={style.text}
              color={props.invertedColors ? 'white' : 'malm0'}
            >
              <Trans key={props.subtitle} i18nKey={props.subtitle} />
            </Text>
          ) : null}

          {props.action ? (
            <Button variant="secondary" onClick={props.action.onClick}>
              {props.action.btnText}
            </Button>
          ) : null}
        </div>
      )}
    </>
  )
}

export const CardSlider = <T extends object>({ items, renderItem }: CardSliderProps<T>): JSX.Element => {
  const { scrollRef, pages, activePageIndex, goTo, snapPointIndexes } = useSnapCarousel()
  return (
    <div className={css.root}>
      <ul className={css.scroll} ref={scrollRef}>
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
          })
        )}
      </ul>
      {pages.length > 1 ? (
        <div className={css.controls} aria-hidden>
          {pages.map((_, i) => (
            <button
              key={i}
              className={css.paginationButton}
              style={{
                ...(activePageIndex === i ? styles.paginationButtonActive : {}),
              }}
              onClick={() => goTo(i)}
            >
              &bull;
            </button>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export interface CardSliderItemProps {
  readonly isSnapPoint: boolean
  readonly children?: React.ReactNode
}

export const CardSliderItem = ({ isSnapPoint, children }: CardSliderItemProps): JSX.Element => (
  <li
    className={css.item}
    style={{
      ...(isSnapPoint ? styles.itemSnapPoint : {}),
    }}
  >
    {children}
  </li>
)
