import { Box, Container, Heading, Text } from 'atomic'
import { Link } from 'components/link/Link'
import React, { FC } from 'react'
import Button from 'atomic/components/atoms/button/Button'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import * as css from './FullbleedHero.css'
import { ouTheme, ThemeFontSizes, themeVars } from 'atomic/styles/theme.css'
import { HeroButton, HeroVideo } from '../../HeroGeneric'
import { useMediaQuery } from 'react-responsive'

type FullbleedHeroProps = {
  heading: string
  headingLineHeight?: keyof typeof themeVars.lineHeights
  intro: string
  videoSettings?: HeroVideo
  image: string
  overlayOpacity: number
  imagePosition: 'center' | 'top' | 'bottom' | 'left' | 'right'
  detailedImagePosition: number
  richTextHeading: string
  uppercase: boolean
  richTextIntro: string
  headingSize: keyof ThemeFontSizes
  fontSize: keyof ThemeFontSizes
  button: HeroButton
} & BuilderProps

const FullbleedHero: FC<FullbleedHeroProps> = ({
  heading,
  headingLineHeight,
  intro,
  videoSettings,
  image,
  overlayOpacity,
  imagePosition,
  detailedImagePosition,
  richTextHeading,
  uppercase,
  richTextIntro,
  headingSize,
  fontSize,
  button,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const overlay = overlayOpacity * 0.01
  const smallScreen = useMediaQuery({ minWidth: ouTheme.breakpoints.mobile, maxWidth: ouTheme.breakpoints.tablet })

  return (
    <Box
      position={'relative'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      style={{
        backgroundImage: smallScreen
          ? `url(${videoSettings?.posterMobileImage})`
          : smallScreen && videoSettings?.videosrc && !videoSettings?.posterMobileImage
          ? `url(${videoSettings?.posterImage})`
          : videoSettings?.videosrc && !smallScreen
          ? 'none'
          : `url(${image})`,
        backgroundPosition: !videoSettings?.videosrc
          ? imagePosition && detailedImagePosition
            ? `${detailedImagePosition}% ${imagePosition}`
            : imagePosition
            ? imagePosition
            : 'center'
          : 'none',
      }}
      className={css.image}
      {...cleanProps}
    >
      <Box position={'absolute'} className={css.gradientOverlay} style={{ opacity: overlay }} />
      {videoSettings?.videosrc && !smallScreen && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          className={css.fullBleedVideo}
          poster={videoSettings?.posterImage}
          muted={videoSettings?.muted}
          loop={videoSettings?.loop}
          playsInline
          autoPlay
          preload={'metadata'}
        >
          <source src={videoSettings.videosrc} type={`video/${videoSettings.fileType ?? 'mp4'}`} />
        </video>
      )}
      <Container className={css.container} position={'relative'} padding={{ mobile: 'l', desktop: 'xxl' }}>
        {/* Empty column to match layout */}
        <div />
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={'xxl'}>
          <Box display={'flex'} flexDirection={'column'} gap={'m'} alignItems={'center'} justifyContent={'center'}>
            {!richTextHeading ? (
              <Heading
                as={'h1'}
                fontSize={headingSize ?? 'xxxl'}
                margin={'none'}
                textAlign={'center'}
                color={'white'}
                fontWeight={'extrabold'}
                lineHeight={headingLineHeight ?? 'xs'}
                style={{ textTransform: uppercase ? 'uppercase' : 'initial' }}
              >
                {heading}
              </Heading>
            ) : (
              <h1
                className={css.richTextHeading}
                style={{
                  textTransform: uppercase ? 'uppercase' : 'initial',
                  fontSize: headingSize ?? themeVars.fontSizes.xxxl,
                  lineHeight: headingLineHeight ?? 'xs',
                }}
                dangerouslySetInnerHTML={{ __html: richTextHeading }}
              />
            )}
            {!richTextIntro ? (
              <Text fontWeight={'normal'} textAlign={'center'} margin={'none'} fontSize={fontSize ?? 'l'} color={'white'}>
                {intro}
              </Text>
            ) : (
              <Box
                fontWeight={'normal'}
                textAlign={'center'}
                margin={'none'}
                fontSize={fontSize ?? 'l'}
                color={'white'}
                dangerouslySetInnerHTML={{ __html: richTextIntro }}
              />
            )}
            {button && (
              <Link href={button?.link} style={{ paddingTop: themeVars.spaces.m }}>
                <Button
                  variant={button?.type}
                  size={button?.size}
                  style={
                    (button.darkMode && button?.type === 'secondary') || button?.type === 'toggle'
                      ? { borderColor: 'white', color: 'white' }
                      : button.darkMode && button?.type === 'primary'
                      ? { backgroundColor: 'white', color: '#222' }
                      : {}
                  }
                >
                  {button?.label}
                </Button>
              </Link>
            )}
          </Box>
        </Box>
        {/* Empty column to match layout */}
        <div />
      </Container>
    </Box>
  )
}

export default FullbleedHero
