import React, { FC } from 'react'
import { ThemeColors } from 'atomic/styles/theme.css'
import { Box, Container } from 'atomic'
import FactsItem, { FactItem } from './FactItem'
import * as css from './Facts.css'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type FactsProps = {
  backgroundColor: keyof ThemeColors
  fullbleedLayout: boolean
  funFact1: FactItem
  funFact2: FactItem
  funFact3: FactItem
} & BuilderProps

const Facts: FC<FactsProps> = ({ backgroundColor, fullbleedLayout, funFact1, funFact2, funFact3, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      backgroundColor={fullbleedLayout ? backgroundColor : 'transparent'}
      style={{ width: '100%' }}
      {...cleanProps}
    >
      <Container
        display={{ mobile: 'flex' }}
        flexDirection={{ mobile: 'column' }}
        backgroundColor={backgroundColor}
        gap={'xl'}
        paddingY={'xxxl'}
        paddingX={{ mobile: 'l', desktop: 'xxxl' }}
        className={css.container}
      >
        <FactsItem fact={funFact1} />
        <FactsItem fact={funFact2} />
        <FactsItem fact={funFact3} />
      </Container>
    </Box>
  )
}

export default Facts
