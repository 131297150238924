// Inspired by https://www.storyblok.com/tp/nuxtjs-translated-slugs#lets-use-dynamic-urls-with-storyblok

import { createContext, FC, ReactNode, useContext } from 'react'

import { defaultLocale } from '../components/languagepicker/LanguageHelper'

const prodEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

export type StoryBlokLink = {
  id: string
  slug: string
  name: string
  real_path: string
  alternates: StoryBlokLinkAlternate[]
}

export type StoryBlokLinkAlternate = {
  path: string
  name: string
  lang: string
}

export type PageAlternateLink = {
  hreflang: string
  href: string
}

export type NavigationHeader = {
  url: string
  text: string
  hide?: boolean
  campaign?: boolean
  navItems?: NavigationItem[]
}

export type NavigationItem = {
  url: string
  text: string
  hide?: boolean
  campaign?: boolean
}

export type TopBanner = {
  text: string
  url: string
  textColor: string
  backgroundColor: string
  showTrustpilotStars?: boolean
}

type LinkContextType = {
  getLocalePath: (slug: string, locale: string) => string
  getLocaleTitle: (slug: string, locale: string) => string
  getPageAlternates: (slug: string, host: string, defaultLocale: string) => PageAlternateLink[]
  getCanonicalLocale: (slug: string, locale: string, defaultLocale: string) => string
  getPathWithoutTrailingSlash: (path: string) => string
}

const LinkContextDefaultValues: LinkContextType = {
  getLocalePath: () => '',
  getLocaleTitle: () => '',
  getPageAlternates: () => [],
  getCanonicalLocale: () => '',
  getPathWithoutTrailingSlash: () => '',
}

const defaultLanguageVariants: { lang: string; defaultVariant: string }[] = [
  { lang: 'en', defaultVariant: 'en-gb' },
  { lang: 'sv', defaultVariant: 'sv-se' },
  { lang: 'fr', defaultVariant: 'fr-fr' },
]

const LinkContext = createContext<LinkContextType>(LinkContextDefaultValues)

export function useLink(): LinkContextType {
  return useContext(LinkContext)
}

type PropTypes = {
  links: StoryBlokLink[]
  children: ReactNode
}

export const LinkProvider: FC<PropTypes> = ({ children, links }) => {
  const getLocalePath = (slug: string, locale: string): string => {
    if (!links) {
      return slug
    }

    // if default root or default root in storyblok preview mode
    if (slug === '/' || slug.startsWith('/?')) {
      return slug
    }

    // remove the first character, but only if it's a slash
    if (slug.startsWith('/')) {
      slug = slug.substring(1)
    }

    if (slug.includes('?')) {
      slug = slug.split('?').shift()
    }

    let anchorId = ''
    if (slug.includes('#')) {
      const splitSlug = slug.split('#')
      slug = splitSlug[0]
      anchorId = splitSlug[1]
    }

    const linkObject = links.find((link) => {
      if (link.slug === slug) {
        return link
      }

      return link.alternates.find((alternate) => alternate.path === slug)
    })

    if (!linkObject) {
      return slug
    }

    if (locale === defaultLocale) {
      return anchorId?.length > 0 ? `${linkObject.real_path}#${anchorId}` : `${linkObject.real_path}`
    }

    const alternatePath = linkObject.alternates.find((alternate) => alternate.lang === locale)?.path
    if (alternatePath) {
      return anchorId?.length > 0 ? `/${alternatePath}#${anchorId}` : `/${alternatePath}`
    }

    // fallback
    return linkObject.real_path
  }

  const getLocaleTitle = (slug: string, locale: string): string => {
    const titleSuffix = slug !== '/' ? ' | Once Upon' : ''

    //if prod and wip-page
    if (prodEnvironment && slug.toLowerCase().includes('wip')) {
      return slug + titleSuffix
    }

    // if default root or default root in storyblok preview mode
    if (slug === '/' || slug.startsWith('/?')) {
      slug = '/home'
    }

    // remove the first character, but only if it's a slash
    if (slug.startsWith('/')) {
      slug = slug.substring(1)
    }

    if (slug.includes('?')) {
      slug = slug.split('?').shift()
    }

    // if anchor link
    if (slug.includes('#')) {
      slug = slug.split('#').shift()
    }

    let linkObject = links.find((link) => {
      if (link.slug === slug) {
        return link
      }
      return link.alternates.find((alternate) => alternate.path === slug)
    })

    if (!linkObject) {
      linkObject = links.find((link) => link.slug === 'not-found')
    }

    if (locale === defaultLocale) {
      return linkObject.name + titleSuffix
    }

    const alternateTitle = linkObject.alternates.find((alternate) => alternate.lang === locale)?.name

    if (!alternateTitle) {
      return linkObject.name + titleSuffix
    }

    return alternateTitle + titleSuffix
  }

  const getCanonicalLocale = (slug: string, locale: string, defaultLocale: string): string => {
    // if we're at the blog, but the chosen locale is not yet supported, the canonical should be the default locale
    if (isBlog(slug) && !isLocaleSupportedByBlog(locale)) {
      return defaultLocale
    }

    return locale
  }

  const getPageAlternates = (slug: string, host: string, defaultLocale: string): PageAlternateLink[] => {
    const pageAlternates: PageAlternateLink[] = []

    // if default root or default root in storyblok preview mode
    if (slug === '/' || slug.startsWith('/?')) {
      slug = '/home'
    }

    if (!links) {
      return []
    }

    // remove the first character, but only if it's a slash
    if (slug.startsWith('/')) {
      slug = slug.substring(1)
    }

    if (slug.includes('?')) {
      slug = slug.split('?').shift()
    }

    links.find((link) => {
      // we also have to check alternates, as we might be on a translated slug, which can only be found within the alternates
      if (link.slug === slug || link.alternates.some((alt) => alt.path === slug)) {
        // add x-default, to catch any language code we're not supporting, and send those to the default locale
        const xDefault: PageAlternateLink = {
          hreflang: 'x-default',
          href: host + (link.slug === 'home' ? '' : '/' + link.slug),
        }
        pageAlternates.push(xDefault)

        // add the default locale, as it's obviously not listed among the alternates
        const defaultLang: PageAlternateLink = {
          hreflang: defaultLocale,
          href: host + (link.slug === 'home' ? '' : '/' + link.slug),
        }
        pageAlternates.push(defaultLang)

        // blog is a special slug that needs special attention
        if (isBlog(slug)) {
          link.alternates = filterOutAllUnsupportedLanguagesForBlog(link.alternates)
        }

        for (const alternate of link.alternates) {
          if (alternate.path === 'home') {
            alternate.path = ''
          }

          // get the first two characters, the language code, if it contains the language+region code (i.e. get 'en' from 'en-GB')
          const languageCode = alternate.lang.length > 2 ? alternate.lang.slice(0, 2) : alternate.lang
          if (languageCode === 'pl') {
            // we don't support polish
            continue
          }
          let hreflang = alternate.lang

          // if a language code is repeated, i.e. en-gb, en-au and en-us, we need to include region in hreflang
          const alternateLanguages = link.alternates.map((a) => a.lang)
          alternateLanguages.push(defaultLocale)
          const nrOfLanguageRegions = alternateLanguages.filter((x) => x.startsWith(languageCode)).length
          if (nrOfLanguageRegions > 1) {
            hreflang = alternate.lang // keep the region part of the language code, as we seem to have multiple variants of that language

            addDefaultLanguageVariant(languageCode, pageAlternates, host, defaultLocale, alternate)
          }

          const alternateLink: PageAlternateLink = {
            hreflang: hreflang,
            href: host + (alternate.lang ? '/' + alternate.lang : '') + (alternate.path ? '/' + alternate.path : ''),
          }

          pageAlternates.push(alternateLink)
        }
      }
    })

    return pageAlternates
  }

  // Add default language variants if we have more than one variant of a language, i.e. for "en" (english) we have "en-gb", "en-us" and "en-au".
  // Look up the set default variant and add a link for it.
  const addDefaultLanguageVariant = (
    languageCode: string,
    pageAlternates: PageAlternateLink[],
    host: string,
    defaultLocale: string,
    alternate: StoryBlokLinkAlternate
  ): void => {
    const defaultLanguageVariant = defaultLanguageVariants.filter((x) => x.lang === languageCode)
    if (defaultLanguageVariant && defaultLanguageVariant.length > 0) {
      const defaultVariant = defaultLanguageVariant[0].defaultVariant

      if (!pageAlternates.some((obj) => obj.hreflang === languageCode)) {
        const defaultLink: PageAlternateLink = {
          hreflang: languageCode,
          href:
            host + (defaultVariant && defaultVariant !== defaultLocale ? '/' + defaultVariant : '') + (alternate.path ? '/' + alternate.path : ''),
        }

        pageAlternates.push(defaultLink)
      }
    }
  }

  const filterOutAllUnsupportedLanguagesForBlog = (alternates: StoryBlokLinkAlternate[]): StoryBlokLinkAlternate[] => {
    const filteredAlternates: StoryBlokLinkAlternate[] = []

    for (const alternate of alternates) {
      if (isBlog(alternate.path)) {
        if (isLocaleSupportedByBlog(alternate.lang)) {
          filteredAlternates.push(alternate)
        }
      }
    }

    return filteredAlternates
  }

  const isBlog = (slug: string): boolean => {
    const defaultLocaleSlug = getLocalePath(slug, 'en-gb')
    return defaultLocaleSlug === 'stories' || defaultLocaleSlug.indexOf('stories/') === 0 || defaultLocaleSlug.indexOf('/stories') === 0
  }

  const isLocaleSupportedByBlog = (locale: string): boolean => {
    const available_languages: string[] = ['en-gb', 'en-us', 'en-au', 'en-ca', 'nl-nl', 'de-de', 'sv-se', 'it-it', 'fr-fr', 'es-es']
    return available_languages?.includes(locale)
  }

  const avoidAdsUrlsToBeIndexed = (path: string): string => {
    if (path.indexOf('&af_adset_id') > -1 || path.indexOf('?af_adset_id') > -1) {
      path = path.substring(0, path.indexOf('?'))
    }
    return path
  }

  const getPathWithoutTrailingSlash = (path: string): string => {
    let pathWithoutTrailingSlash = path.slice(-1) === '/' ? path.slice(0, -1) : path
    pathWithoutTrailingSlash = avoidAdsUrlsToBeIndexed(pathWithoutTrailingSlash)
    return pathWithoutTrailingSlash
  }

  const value = {
    getLocalePath,
    getLocaleTitle,
    getPageAlternates,
    getCanonicalLocale,
    getPathWithoutTrailingSlash,
  }

  return (
    <>
      <LinkContext.Provider value={value}>{children}</LinkContext.Provider>
    </>
  )
}
