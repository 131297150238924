import { ImageSlider, ImageSliderItem } from 'components/imageslider/ImageSlider'
import { FC } from 'react'
import { container } from './ImageSliderComponent.css'
import Image from 'next/image'
import { Container, Heading, Text } from 'atomic'
import Box, { BoxProps } from 'atomic/components/atoms/box/Box'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import { ouTheme } from 'atomic/styles/theme.css'

export type Slide = { id: number; src: string }
export type Slides = Omit<Slide, 'id'>[]

type ImageSliderProps = {
  slides: Slides
  heading: string
  body: string
  imageGap?: boolean
} & BoxProps &
  BuilderProps

const ImageSliderComponent: FC<ImageSliderProps> = ({ imageGap = false, ...props }) => {
  const slides: Slide[] = props?.slides?.map((s, index) => ({ id: index, src: s.src }))

  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Container className={container} {...cleanProps}>
      <Box>
        {props.heading && (
          <Heading as={'h3'} fontSize={'xl'} fontWeight={'bold'} textAlign={props.textAlign} paddingBottom={!props.body ? 'l' : 'none'}>
            {props.heading}
          </Heading>
        )}
        {props.body && <Text paddingBottom={'l'}>{props.body}</Text>}
      </Box>
      {slides && slides.length > 0 ? (
        <ImageSlider
          items={slides}
          imageGap={imageGap}
          renderItem={({ item, isSnapPoint }) => (
            <ImageSliderItem key={item.id ?? item.src} isSnapPoint={isSnapPoint}>
              <Image
                src={item.src}
                style={{ objectFit: 'cover' }}
                alt="Placeholder"
                fill
                sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
              />
            </ImageSliderItem>
          )}
        />
      ) : null}
    </Container>
  )
}

export default ImageSliderComponent
