import DynamicLink from 'components/dynamiclink/DynamicLink'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from 'atomic'
import { storyblokEditable } from '@storyblok/react'

import { trackOutboundLink } from '../../../lib/analytics-utils'

import styles from './LinkButton.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const LinkButton: FC<PropTypes> = ({ blok }) => {
  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      <DynamicLink linkObject={blok.link}>
        <Button onClick={() => trackOutboundLink(blok.link.url)}>{blok.label}</Button>
      </DynamicLink>
    </div>
  )
}

export default LinkButton
