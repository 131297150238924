import womanWithKey from '/public/images/illustrations-woman-with-key.svg'
import axios from 'axios'
import { Loadingstatus } from 'common/enums'
import { isEmpty } from 'lodash'
import Image from 'next/image'
import { FC, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { StoryblokContent } from 'types/StoryblokContent'
import log from 'utils/logger'

import { ErrorMessage } from '@hookform/error-message'
import Modal from 'components/modal/Modal'
import { Button, Box } from 'atomic'

import styles from './CollaborateWithUs.module.scss'
import { useSafeTranslation } from 'hooks/useSafeTranslation'

type PropTypes = {
  blok?: StoryblokContent
}

export type Target = {
  name: { value: string; name: string }
  email: { value: string; name: string }
  country: { value: string; name: string }
  link1: { value: string; name: string }
  link2: { value: string; name: string }
  link3: { value: string; name: string }
  message: { value: string; name: string }
  [key: string]: { value: string; name: string }
}

const CollaborateWithUs: FC<PropTypes> = () => {
  const { t } = useSafeTranslation()
  const [loadingStatus, setLoadingStatus] = useState<Loadingstatus>(Loadingstatus.IDLE)
  const recaptchaRef = useRef(null)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onTouched' })

  const emailValidation = {
    value:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: t('authentication_error_emailwrongformat'),
  }

  const subject = (data): string => {
    return `Collaboration request - ${data.country} - ${data.email}`
  }

  const message = (data): string => {
    return `
    <br/>
    <b>Name:</b> ${data.name}<br/>
    <b>Email:</b> ${data.email}<br/>
    <b>Country:</b> ${data.country}<br/>
    <b>Social Media Link:</b> ${data.link1}<br/>
    <b>Social Media Link (optional):</b> ${data.link2}<br/>
    <b>Social Media Link (optional):</b> ${data.link3}<br/>
    <b>Message:</b> ${data.message}<br/>
    <br/>
    `
  }

  const onSubmit = handleSubmit(async (data): Promise<void> => {
    setLoadingStatus(Loadingstatus.LOADING)
    const token = await recaptchaRef.current.executeAsync()
    await handleOnSubmit(data, token)
      .then(() => {
        reset()
        setLoadingStatus(Loadingstatus.SUCCESS)
      })
      .catch((error) => {
        log.error(error)
        setLoadingStatus(Loadingstatus.IDLE)
      })

    // Reset the reCAPTCHA so that it can be executed again if user submits another email.
    recaptchaRef.current.reset()
  })

  const handleOnSubmit = async (data, token: string): Promise<void> => {
    const url = process.env.NEXT_PUBLIC_COLLABORATION_FORM_URL

    const formData = new FormData()
    formData.append('email', data.email)
    formData.append('captcha', token)
    formData.append('subject', subject(data))
    formData.append('message', message(data))

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }

    return await axios.post(url, formData, config)
  }

  return (
    <div className={styles.container}>
      <div className={styles.introContainer}>
        <h2>{t('web_influencer_landingpage_title')}</h2>
        <h3 className={styles.bg}>{t('web_influencer_landingpage_subtitle1')}</h3>
        <p>{t('web_influencer_landingpage_text1')}</p>
        <h3 className={styles.bg}>{t('web_influencer_landingpage_subtitle2')}</h3>
        <p>
          {t('web_influencer_landingpage_text2')}
          <br />
          <br />
          {t('web_influencer_landingpage_values_text')}
        </p>
      </div>
      <div className={styles.valuesContainer}>
        <div className={styles.illustration}>
          <Image alt="woman with key" src={womanWithKey} />
        </div>
        <div className={styles.values}>
          <h3>{t('web_influencer_landingpage_vision_title')}:</h3>
          <p>{t('web_influencer_landingpage_vision_text')}</p>

          <h3>{t('web_influencer_landingpage_mission_title')}:</h3>
          <p>{t('web_influencer_landingpage_mission_text')}</p>
          <h3>{t('web_influencer_landingpage_values_title')}:</h3>
          <p>
            {t('web_influencer_landingpage_values_wecare')}
            <br />
            {t('web_influencer_landingpage_values_wedare')}
            <br />
            {t('web_influencer_landingpage_values_wearefree')}
          </p>
        </div>
      </div>
      <div className={styles.formContainer}>
        <form name="signup" onSubmit={onSubmit} className={styles.form} noValidate>
          <h3>{t('web_influencer_landingpage_form_contactus')}</h3>
          <div className={styles.inputs}>
            <div className={styles.row}>
              <div className={styles.inputContainer}>
                <label>
                  <div className={styles.required}>{t('web_influencer_landingpage_form_name')}</div>
                  <input
                    data-testid="name-input"
                    className={errors.name && 'error'}
                    name="name"
                    type="text"
                    {...register('name', { required: true })}
                  />
                </label>
                <ErrorMessage errors={errors} name="name" render={({ message }) => <p className={styles.errorMessage}>{message}</p>} />
              </div>
              <div className={styles.inputContainer}>
                <label>
                  <div className={styles.required}>{t('web_influencer_landingpage_form_country')}</div>
                  <input
                    data-testid="country-input"
                    className={errors.country && 'error'}
                    name="country"
                    type="text"
                    {...register('country', { required: true })}
                  />
                </label>
                <ErrorMessage errors={errors} name="country" render={({ message }) => <p className={styles.errorMessage}>{message}</p>} />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.inputContainer}>
                <label>
                  <div className={styles.required}>{t('web_influencer_landingpage_form_email')}</div>
                  <input
                    data-testid="email-input"
                    className={errors.email && 'error'}
                    name="email"
                    type="email"
                    {...register('email', { required: String(t('authentication_error_emailwrongformat')), pattern: emailValidation })}
                  />
                </label>
                <ErrorMessage errors={errors} name="email" render={({ message }) => <p className={styles.errorMessage}>{message}</p>} />
              </div>
              <div className={styles.inputContainer}>
                <label>
                  <div className={styles.required}>{t('web_influencer_landingpage_form_socialmedialink')}</div>
                  <input
                    data-testid="link1-input"
                    className={errors.link1 && 'error'}
                    name="link1"
                    type="text"
                    {...register('link1', { required: true })}
                  />
                </label>
                <ErrorMessage errors={errors} name="link1" render={({ message }) => <p className={styles.errorMessage}>{message}</p>} />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.inputContainer}>
                <label>
                  <div>
                    {t('web_influencer_landingpage_form_socialmedialink')} ({t('web_influencer_landingpage_form_optional')})
                  </div>
                  <input data-testid="link2-input" className={errors.link2 && 'error'} name="link2" type="text" {...register('link2')} />
                </label>
                <ErrorMessage errors={errors} name="link2" render={({ message }) => <p className={styles.errorMessage}>{message}</p>} />
              </div>
              <div className={styles.inputContainer}>
                <label>
                  <div>
                    {t('web_influencer_landingpage_form_socialmedialink')} ({t('web_influencer_landingpage_form_optional')})
                  </div>
                  <input data-testid="link3-input" className={errors.link3 && 'error'} name="link3" type="text" {...register('link3')} />
                </label>
                <ErrorMessage errors={errors} name="link3" render={({ message }) => <p className={styles.errorMessage}>{message}</p>} />
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.inputContainer}>
              <label>
                <div className={styles.required}>{t('web_influencer_landingpage_form_message')}</div>
                {/*  <div className={styles.description}>
                  Vi hjälper dig gärna! Beskriv ditt ärende så ingående som möjligt så kan vi hjälpa dig lite snabbare...
                </div> */}
                <textarea
                  data-testid="message-input"
                  className={errors.message && 'error'}
                  name="message"
                  {...register('message', { required: true })}
                ></textarea>
              </label>
              <ErrorMessage errors={errors} name="message" render={({ message }) => <p className={styles.errorMessage}>{message}</p>} />
            </div>
          </div>
          <div className={styles.buttons}>
            <div className={styles.row}>
              <Button variant="secondary" onClick={reset}>
                {t('web_influencer_landingpage_form_empty_form')}
              </Button>
              <Button type="submit" disabled={!isEmpty(errors)}>
                {t('web_influencer_landingpage_form_button_text')}
              </Button>
            </div>
          </div>
        </form>

        <ReCAPTCHA
          ref={recaptchaRef}
          onExpired={() => recaptchaRef.current.reset()}
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        />
      </div>

      <Modal show={loadingStatus === Loadingstatus.SUCCESS} onClosed={() => setLoadingStatus(Loadingstatus.IDLE)}>
        <Box>
          <h1>{t('web_influencer_submition_sent_confirmation_header')}</h1>
          <p>{t('web_influencer_submition_sent_confirmation_body')}</p>
          <Box display="flex" justifyContent="center">
            <Button marginTop="xl" onClick={() => setLoadingStatus(Loadingstatus.IDLE)}>
              {t('web_influencer_submition_sent_confirmation_button')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default CollaborateWithUs
