import React, { FC } from 'react'
import Description from '../form_fields/Description'
import Screenshots, { ScreenshotsDependencies } from '../form_fields/Screenshots'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { FormFields } from '../FormFields'
import OrderNumber from '../form_fields/OrderNumber'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
/* import InformationArrowBox from '../form_fields/InformationArrowBox' */
import Information from '../form_fields/Information'

type PropTypes = {
  errors: { [key: string]: string }
  register: UseFormRegister<FieldValues>
  screenshotsDependencies: ScreenshotsDependencies
}

const CancelOrder: FC<PropTypes> = ({ errors, register, screenshotsDependencies }) => {
  const { t } = useSafeTranslation()
  return (
    <>
      <OrderNumber
        errors={errors}
        register={register(FormFields.OrderNumber.id, {
          required: t('form_validation_text_ohnoyoumissedaspot'),
          pattern: {
            value: /^([O|o][U|u])(\d{3,4})-(.{4,6})$/,
            message: t('form_validation_text_ohnoyoumissedaspot'),
          },
        })}
        required={true}
      />
      {/* <InformationArrowBox header={t('form_information_header_1')} text={t('support_cancelorder_infobox_body')} />
       */}
      <Description
        errors={errors}
        register={register(FormFields.Description.id, {
          required: t('form_description_validation_text'),
        })}
        required={true}
      />
      <Screenshots errors={errors} register={register} screenshotsDependencies={screenshotsDependencies} />
      <Information
        header={t('form_information_header_1')}
        text={t('support_cancelorder_infobox_body')} //"If you want to cancel your order, please provide us with the order number. We will then cancel the order for you."
        illustrationType="lamp-with-kludd"
      />
    </>
  )
}

export default CancelOrder
