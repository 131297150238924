import { FC } from 'react'

const PlusIcon: FC<{ color?: string }> = ({ color }) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9805 3.04004V21.04" stroke={color ?? 'white'} strokeWidth="2" strokeLinecap="round" />
    <path d="M3.98047 12.04H21.9805" stroke={color ?? 'white'} strokeWidth="2" strokeLinecap="round" />
  </svg>
)

export default PlusIcon
