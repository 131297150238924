import DynamicLink from 'components/dynamiclink/DynamicLink'
import { FC } from 'react'
import { StoryblokContent } from 'types/StoryblokContent'

import { Button } from 'atomic'
import { storyblokEditable } from '@storyblok/react'

import StoryblokImage from '../../storyblokimage/StoryblokImage'

import styles from './ImageWithTextAndCta.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const ImageWithTextAndCta: FC<PropTypes> = ({ blok }) => {
  return (
    <section className={styles.sectionContainer} {...storyblokEditable(blok)}>
      <span className={styles.anchor} id={blok.anchor_id} />
      <div className={styles.contentContainer}>
        <div className={styles.articleListContainer}>
          <div>
            <div className={`${styles.articleContainer}`}>
              <div className={`${styles.column} ${styles.articleImage}`}>
                <StoryblokImage
                  src={blok.image?.filename}
                  width={640}
                  height={640}
                  quality={100}
                  alt={blok.image?.name}
                  objectPosition={blok.image_focus ?? 'center'}
                />
              </div>
              <div className={`${styles.column} ${styles.articleText}`}>
                <div className={styles.columnContentContainer}>
                  <div className={styles.title}>
                    <h2>{blok.title}</h2>
                  </div>
                  <div className={styles.description}>{blok.description}</div>
                  <div className={styles.buttonContainer}>
                    <DynamicLink linkObject={blok.cta_url}>
                      <Button variant="secondary">{blok.cta_text}</Button>
                    </DynamicLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageWithTextAndCta
