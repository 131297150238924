import clsx from 'clsx'
import React from 'react'

import styles from './Loader.module.scss'

export const loaderThemes = ['neutral', 'light', 'primary'] as const

export type PropTypes = {
  className?: string
  theme?: (typeof loaderThemes)[number]
}

const Loader: React.FC<PropTypes> = (props) => {
  const { className, theme = 'primary' } = props
  const classNames = clsx([className, styles.loader, styles[theme]])

  return <div className={classNames} data-testid="loader" />
}

export default Loader
