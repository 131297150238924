import { Box, Button } from 'atomic'
import React, { FC, Fragment, useRef, useState } from 'react'
//import styles from './FormCss.module.scss'
import * as styles from './FormCss.css'
import { ListItem } from 'components/dropdown/Dropdown'
import TechnicalIssues from './support_forms/TechnicalIssues'
import Delivery from './support_forms/Delivery'
import axios from 'axios'
import log from 'utils/logger'
import Loader from 'components/loader/Loader'
import { FormField, FormFields } from './FormFields'
import Firstname from './form_fields/Firstname'
import Surname from './form_fields/Surname'
import Email from './form_fields/Email'
import { SubmitMessage, SubmitMessageStatus } from 'components/submitmessage/SubmitMessage'
import Modal from 'components/modal/Modal'
import SucceedView from './SucceedView'
import { useForm } from 'react-hook-form'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import ReCAPTCHA from 'react-google-recaptcha'
import ChangeTheDeliveryAddress from './support_forms/ChangeDeliveryAddress'
import MyAccount from './support_forms/MyAccount'
import QuestionsAboutOrder from './support_forms/QuestionsAboutOrder'
import Complaints from './support_forms/Complaints'
import CancelOrder from './support_forms/CancelOrder'
import Other from './support_forms/Other'
import PaymentQuestions from './support_forms/PaymentQuestions'
import SelectErrandType from './form_fields/SelectErrandType'

enum SupportErrandType {
  TechnicalIssues = 'Technical issues',
  Delivery = 'Delivery',
  ChangeDeliveryAddress = 'Change the delivery address',
  MyAccount = 'My account',
  QuestionsAboutOrder = 'Questions about my order',
  PaymentQuestions = 'Payment questions',
  Complaints = 'Complaints',
  CancelOrder = 'Cancel order',
  Other = 'Other',
}

export type Target = {
  firstname: { value: string; name: string }
  surname: { value: string; name: string }
  email: { value: string; name: string }
  [key: string]: { value: string; name: string }
}

const getSupportErrandTypes = (): ErrandTypeItem[] => {
  const { t } = useSafeTranslation()
  const supportErrandTypes: ErrandTypeItem[] = [
    {
      label: t('form_support_errandtype_technicalissues'),
      key: 'Technical issues',
      input_fields: [FormFields.Device, FormFields.Description, FormFields.Screenshots],
    },
    { label: t('form_support_errandtype_delivery'), key: 'Delivery', input_fields: [FormFields.OrderNumber, FormFields.Description] },
    {
      label: t('form_support_errandtype_changethedeliveryaddress'),
      key: 'Change the delivery address',
      input_fields: [
        FormFields.OrderNumber,
        FormFields.ReceiverFirstname,
        FormFields.ReceiverSurname,
        FormFields.ReceiverPhoneNumber,
        FormFields.StreetAddress,
        FormFields.AddressLine2,
        FormFields.Postcode,
        FormFields.City,
        FormFields.Country,
        FormFields.StateProvinceRegion,
      ],
    },
    {
      label: t('form_support_errandtype_myaccount'),
      key: 'My account',
      input_fields: [FormFields.OrderNumber, FormFields.EmailConnectedToYourAccount, FormFields.Description, FormFields.Screenshots],
    },
    {
      label: t('form_support_errandtype_questionsaboutmyorder'),
      key: 'Questions about my order',
      input_fields: [FormFields.OrderNumber, FormFields.Description, FormFields.Screenshots],
    },
    {
      label: t('form_support_errandtype_paymentquestions'),
      key: 'Payment questions',
      input_fields: [FormFields.OrderNumber, FormFields.Description, FormFields.Screenshots],
    },
    {
      label: t('form_support_errandtype_complaints'),
      key: 'Complaints',
      input_fields: [FormFields.OrderNumber, FormFields.Description, FormFields.Screenshots],
    },
    {
      label: t('form_support_errandtype_cancelorder'),
      key: 'Cancel order',
      input_fields: [FormFields.OrderNumber, FormFields.Description, FormFields.Screenshots],
    },
    {
      label: t('form_support_errandtype_other'),
      key: 'Other',
      input_fields: [FormFields.OrderNumber, FormFields.Description, FormFields.Screenshots],
    },
  ]

  return supportErrandTypes
}

export type ErrandTypeItem = ListItem & {
  input_fields?: FormField[]
  required_input_fields?: string[]
  information_text?: string
}

const SupportForm: FC = () => {
  const { t } = useSafeTranslation()
  const [selectedCategory, setSelectedCategory] = useState<ErrandTypeItem | null>()
  const [submitted, setSubmitted] = useState<boolean>()
  const [files, setFiles] = useState<{ [key: string]: File }>({})
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submitMessage, setSubmitMessage] = useState<SubmitMessage | null>(null)
  const [showSucceedModal, setShowSucceedModal] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const recaptchaRef = useRef(null)
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'onTouched' })

  const subject = (): string => {
    return `${selectedCategory.key}`
  }

  const message = (data): string => {
    const dynamicInputfields = selectedCategory.input_fields
      .filter((field) => field.id !== 'images' && field.id !== 'screenshots' && field.id !== 'custom infobox')
      .map((field) => ({ id: field.id, label: field.label }))
    const dynamicValues: string[] = dynamicInputfields.map(
      (dynamicField) => `<b>${dynamicField.label}:</b> <span style="white-space: break-spaces;">${data[dynamicField.id]}</span>`
    )
    return `
    ${dynamicValues.join('<br/>')}
    <br/>
    <b>${FormFields.Firstname.label}:</b> ${data.firstname}<br/>
    <b>${FormFields.Surname.label}:</b> ${data.surname}<br/>
    <b>${FormFields.Email.label}:</b> ${data.email}<br/>
    <br/>
    `
  }

  const emailValidation = {
    value:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: t('authentication_error_emailwrongformat'),
  }

  const handleOnSubmit = async (data, token: string): Promise<void> => {
    const url = process.env.NEXT_PUBLIC_SUPPORT_FORM_URL
    //const target = event.target as typeof event.target & Target

    const formData = new FormData()
    formData.append('email', data.email)
    formData.append('captcha', token)
    formData.append('subject', subject())
    formData.append('message', message(data))

    const images = Object.values(files)
    images.forEach((image) => {
      formData.append('files', image)
    })

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }

    return await axios.post(url, formData, config)
  }

  const onSubmit = handleSubmit(async (data): Promise<void> => {
    setSubmitted(true)

    const token = await recaptchaRef.current.executeAsync()
    setIsLoading(true)

    if (!token) {
      setIsLoading(false)
      setSubmitMessage(null)
      return
    }

    try {
      await handleOnSubmit(data, token)
      window.scrollTo(0, 0)
      setShowSucceedModal(true)
      resetForm()
    } catch (error) {
      //console.log('här', error)
      log.error(error)
      setSubmitMessage({ status: SubmitMessageStatus.Negative, text: null })
    }

    // Reset the reCAPTCHA so that it can be executed again if user submits another email.
    recaptchaRef.current.reset()
    setIsLoading(false)
  })

  const resetForm = (): void => {
    reset()
    formRef.current.reset()
    setSubmitMessage(null)
    setSelectedCategory(null)
    setFiles && setFiles({})
    //setErrors({})
    setSubmitted(false)
  }

  const onErrandTypeChange = (selectedValue: string): void => {
    const selectedErrandTypeItem = supportErrandTypes.find((item) => item.key === selectedValue)
    changeErrandType(selectedErrandTypeItem)
  }

  const changeErrandType = (item: ListItem): void => {
    clearErrors()
    setSelectedCategory(item as ErrandTypeItem)
  }

  const supportErrandTypes = getSupportErrandTypes()

  return (
    <Box>
      <Modal show={showSucceedModal} onClosed={() => setShowSucceedModal(false)}>
        <Box>
          <SucceedView />
          <Box display="flex" justifyContent="center">
            <Button marginTop="xl" onClick={() => setShowSucceedModal(false)}>
              {t('form_success_button_label')}
            </Button>
          </Box>
        </Box>
      </Modal>
      <form noValidate ref={formRef} onSubmit={onSubmit}>
        <fieldset className={styles.fieldset}>
          <Firstname
            errors={errors}
            register={register(FormFields.Firstname.id, {
              required: String(t('form_firstname_validation_text')),
            })}
            required={true}
          />

          <Surname
            errors={errors}
            register={register(FormFields.Surname.id, {
              required: String(t('form_surname_validation_text')),
            })}
            required={true}
          />
          <Email
            errors={errors}
            register={register(FormFields.Email.id, {
              required: String(t('form_email_validation_text')),
              pattern: emailValidation,
            })}
            required={true}
          />
          <Fragment>
            {/* <Dropdown
              list={supportErrandTypes}
              label={t('form_errandtypes_choose')}
              header={t('form_errandtypes_header')}
              selectedItem={selectedCategory}
              onItemSelected={(item: ErrandTypeItem) => changeErrandType(item)}
              className={!selectedCategory && submitted ? `${styles.invalid} ${styles.dropdown}` : styles.dropdown}
            /> */}
            <SelectErrandType
              errors={errors}
              required={true}
              list={supportErrandTypes}
              onErrandTypeChange={(selectedValue: string) => onErrandTypeChange(selectedValue)}
            />
          </Fragment>
          {selectedCategory?.key === SupportErrandType.TechnicalIssues && (
            <TechnicalIssues
              register={register}
              errors={errors}
              screenshotsDependencies={{ setFiles, getFiles: files, setSubmitMessage, setError, clearErrors, required: true }}
            />
          )}
          {selectedCategory?.key === SupportErrandType.Delivery && <Delivery errors={errors} register={register} />}
          {selectedCategory?.key === SupportErrandType.ChangeDeliveryAddress && <ChangeTheDeliveryAddress errors={errors} register={register} />}
          {selectedCategory?.key === SupportErrandType.MyAccount && (
            <MyAccount
              register={register}
              errors={errors}
              screenshotsDependencies={{ setFiles, getFiles: files, setSubmitMessage, setError, clearErrors, required: false }}
            />
          )}
          {selectedCategory?.key === SupportErrandType.QuestionsAboutOrder && (
            <QuestionsAboutOrder
              register={register}
              errors={errors}
              screenshotsDependencies={{ setFiles, getFiles: files, setSubmitMessage, setError, clearErrors, required: false }}
            />
          )}
          {selectedCategory?.key === SupportErrandType.PaymentQuestions && (
            <PaymentQuestions
              register={register}
              errors={errors}
              screenshotsDependencies={{ setFiles, getFiles: files, setSubmitMessage, setError, clearErrors, required: false }}
            />
          )}
          {selectedCategory?.key === SupportErrandType.Complaints && (
            <Complaints
              register={register}
              errors={errors}
              screenshotsDependencies={{ setFiles, getFiles: files, setSubmitMessage, setError, clearErrors, required: true }}
            />
          )}
          {selectedCategory?.key === SupportErrandType.CancelOrder && (
            <CancelOrder
              register={register}
              errors={errors}
              screenshotsDependencies={{ setFiles, getFiles: files, setSubmitMessage, setError, clearErrors, required: false }}
            />
          )}
          {selectedCategory?.key === SupportErrandType.Other && (
            <Other
              register={register}
              errors={errors}
              screenshotsDependencies={{ setFiles, getFiles: files, setSubmitMessage, setError, clearErrors, required: false }}
            />
          )}

          <Box className={styles.buttonContainer}>
            <Button
              type="reset"
              variant="secondary"
              style={{ justifySelf: 'right' }}
              className={styles.buttonContainerButton}
              onClick={() => resetForm()}
              disabled={isLoading || submitted}
            >
              {t('form_reset_button_label')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              style={{ justifySelf: 'left' }}
              className={styles.buttonContainerButton}
              disabled={isLoading || !selectedCategory || submitted}
            >
              {isLoading ? <Loader /> : t('form_submit_button_label')}
            </Button>
          </Box>
        </fieldset>
      </form>
      <ReCAPTCHA
        ref={recaptchaRef}
        onExpired={() => recaptchaRef.current.reset()}
        size="invisible"
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      />
    </Box>
  )
}

export default SupportForm
