import React, { FC, useEffect, useRef } from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import { Box, Heading, Text } from 'atomic'
import Image from 'next/image'
import * as css from './BlogPuff2.css'
import { ThemeColors, ThemeSpaces, ouTheme } from 'atomic/styles/theme.css'
import { useMediaQuery } from 'react-responsive'
import Button, { ButtonType } from 'atomic/components/atoms/button/Button'
import { Link } from 'components/link/Link'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'

type VideoItem = {
  file: string
  posterImage: string
  loop: boolean
  muted: boolean
  fileType: 'mp4' | 'webm' | 'mov'
  objectFit: 'cover' | 'contain'
}

type ImageItem = {
  file: string
  imagePosition: string
  imageAltText: string
}

type ButtonItem = {
  text: string
  type: ButtonType
  link: string
  size: 'l' | 'm' | 's'
}

type List = {
  dotColor: keyof ThemeColors
  startIndex?: number
  listItem: ListItem[]
  title: string
  text: string
}

type ListItem = {
  title: string
  text: string
}

type SubTitleItem = {
  text: string
  fontFamily: keyof typeof ouTheme.fontFamilies
  fontSize: keyof typeof ouTheme.fontSizes
  fontWeight: keyof typeof ouTheme.fontWeights
  addUnderline: boolean
}

type BlogPuff2Props = {
  mirroredLayout: boolean
  videoCheck: boolean
  image: ImageItem
  video: VideoItem
  subHeadline?: string
  subTitle: SubTitleItem
  headline?: string
  text: string
  invertedColor: boolean
  addList: boolean
  list: List
  useButton: boolean
  button?: ButtonItem
  extraMarginBottom: boolean
  extraMarginBottomSize: keyof ThemeSpaces
} & BuilderProps

const BlogPuff2: FC<BlogPuff2Props> = ({
  mirroredLayout = false,
  videoCheck = false,
  image,
  video,
  subHeadline,
  subTitle,
  headline,
  text,
  invertedColor = false,
  useButton = true,
  addList,
  list,
  button,
  extraMarginBottom = false,
  extraMarginBottomSize = 'xl',
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  const isDesktop = useMediaQuery({ maxWidth: ouTheme.breakpoints.desktop })
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    function isInViewport() {
      const rect = videoRef?.current?.getBoundingClientRect()

      if (!rect || !videoRef.current) {
        return
      }

      if (rect.bottom >= 50 && rect.top <= window.innerHeight - 50 && videoRef.current?.muted) {
        videoRef.current?.play()
        return
      }
      videoRef.current?.pause()
    }
    window.addEventListener('scroll', isInViewport)
    return () => window.removeEventListener('scroll', isInViewport)
  }, [])
  return (
    <>
      <Box position={'relative'} className={css.container} {...cleanProps}>
        <Box className={css.imageContainer} style={{ order: mirroredLayout ? 2 : 1 }}>
          {!videoCheck ? (
            <Image
              src={image?.file}
              fill
              style={{ objectFit: 'cover', objectPosition: `${image?.imagePosition}` }}
              alt={image?.imageAltText ?? 'Image'}
            />
          ) : (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              ref={videoRef}
              className={css.video}
              poster={video?.posterImage}
              muted={video.muted ?? true}
              playsInline
              autoPlay={video.muted ? false : true}
              controls={video.muted ? false : true}
              loop={video.loop ?? true}
              preload={'metadata'}
              style={{ objectFit: video?.objectFit ?? 'contain' }}
            >
              <source src={video.file} type={`video/${video.fileType ?? 'mp4'}`} />
            </video>
          )}
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={mirroredLayout ? 'flex-end' : 'flex-start'}
          paddingLeft={{ mobile: 's-l', desktop: mirroredLayout ? 's-l' : 'xxxl' }}
          paddingRight={{ mobile: 's-l', desktop: mirroredLayout ? 'xxxl' : 's-l' }}
          paddingY={{ mobile: 'xxl', desktop: 'xxxl' }}
          style={{ order: mirroredLayout ? 1 : 2 }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={'l'} style={{ maxWidth: !isDesktop ? 600 : undefined }}>
            <Box display={'flex'} flexDirection={'column'} gap={'l'} position={'relative'}>
              {subHeadline && !subTitle && (
                <Text fontSize={'l'} fontWeight={'light'} color={invertedColor ? 'white' : 'malm0'} marginBottom={'none'}>
                  {subHeadline}
                </Text>
              )}
              {subTitle && (
                <>
                  <Text
                    fontFamily={subTitle.fontFamily ?? 'primary'}
                    fontSize={subTitle.fontSize ?? 'l'}
                    fontWeight={subTitle.fontWeight ?? 'light'}
                    color={invertedColor ? 'white' : 'malm0'}
                    marginBottom={'none'}
                  >
                    {subTitle.text}
                  </Text>

                  {subTitle.addUnderline && <Illustration name={'underline'} className={css.underline} />}
                </>
              )}
              <Box display={'flex'} flexDirection={'column'} gap={'m'}>
                {headline && (
                  <Heading
                    fontSize={'xl'}
                    fontWeight={'extrabold'}
                    as={'h3'}
                    color={invertedColor ? 'white' : 'malm0'}
                    marginBottom={list ? 's' : 'none'}
                  >
                    {headline}
                  </Heading>
                )}
                {text && (
                  <Text fontSize={'base'} fontWeight={'light'} color={invertedColor ? 'white' : 'malm0'} lineHeight={'l'}>
                    {text}
                  </Text>
                )}
                {addList &&
                  list &&
                  list.listItem &&
                  list.listItem.map((item, index) => (
                    <Box key={index} display={'flex'} alignItems={'center'} marginBottom={'none'}>
                      <Box>
                        <Heading
                          as={'h4'}
                          fontWeight={'semibold'}
                          fontSize={'base'}
                          marginBottom={'xs'}
                          alignItems={'center'}
                          gap={'xs'}
                          color={invertedColor ? 'white' : 'malm0'}
                          className={css.listTitle}
                        >
                          <Box as={'div'} background={list.dotColor} justifyContent={'center'} textAlign={'center'} className={css.dot}>
                            <Text fontFamily="accent" className={css.dotText}>
                              {index + list.startIndex}
                            </Text>
                          </Box>
                          {item.title}
                        </Heading>
                        <Text fontSize={'base'} color={invertedColor ? 'white' : 'malm0'} fontWeight={'light'}>
                          {item.text}
                        </Text>
                      </Box>
                    </Box>
                  ))}
              </Box>
            </Box>
            {button && useButton && (
              <Link href={button.link} style={{ outline: 'none' }}>
                <Button variant={button.type} size={button.size} style={{ outline: 'none' }}>
                  {button.text}
                </Button>
              </Link>
            )}
          </Box>
        </Box>
      </Box>
      {extraMarginBottom && <Box paddingBottom={extraMarginBottomSize} />}
    </>
  )
}

export default BlogPuff2
