import React, { FC } from 'react'
import Kludd04 from './Kludd04'
import Kludd13 from './Kludd13'
import Kludd09 from './Kludd09'
import Kludd01 from './Kludd01'
import Kludd07 from './Kludd07'
import Kludd05 from './Kludd05'
import { ThemeColors } from 'atomic/styles/theme.css'
import Kludd02 from './Kludd02'
import Kludd03 from './Kludd03'
import Kludd06 from './Kludd06'
import Kludd08 from './Kludd08'
import Kludd10 from './Kludd10'
import Kludd11 from './Kludd11'
import Kludd12 from './Kludd12'
import Kludd14 from './Kludd14'
import Kludd15 from './Kludd15'
import Kludd16 from './Kludd16'
import Kludd17 from './Kludd17'
import Kludd18 from './Kludd18'
import Kludd19 from './Kludd19'

export const kluddIllustrationTypes = [
  'kludd-1',
  'kludd-2',
  'kludd-3',
  'kludd-4',
  'kludd-5',
  'kludd-6',
  'kludd-7',
  'kludd-8',
  'kludd-9',
  'kludd-10',
  'kludd-11',
  'kludd-12',
  'kludd-13',
  'kludd-14',
  'kludd-15',
  'kludd-16',
  'kludd-17',
  'kludd-18',
  'kludd-19',
] as const

export type KluddIllustrationType = (typeof kluddIllustrationTypes)[number]

export type KluddPropTypes = {
  name: KluddIllustrationType
  color: keyof ThemeColors
  width?: number
  height?: number
  className?: string
}

const KluddIllustration: FC<KluddPropTypes> = ({ name, color, className, width, height }) => {
  switch (name) {
    case 'kludd-1':
      return <Kludd01 className={className} color={color} width={width} height={height} />
    case 'kludd-2':
      return <Kludd02 className={className} color={color} width={width} height={height} />
    case 'kludd-3':
      return <Kludd03 className={className} color={color} width={width} height={height} />
    case 'kludd-4':
      return <Kludd04 className={className} color={color} width={width} height={height} />
    case 'kludd-5':
      return <Kludd05 className={className} color={color} width={width} height={height} />
    case 'kludd-6':
      return <Kludd06 className={className} color={color} width={width} height={height} />
    case 'kludd-7':
      return <Kludd07 className={className} color={color} width={width} height={height} />
    case 'kludd-8':
      return <Kludd08 className={className} color={color} width={width} height={height} />
    case 'kludd-9':
      return <Kludd09 className={className} color={color} width={width} height={height} />
    case 'kludd-10':
      return <Kludd10 className={className} color={color} width={width} height={height} />
    case 'kludd-11':
      return <Kludd11 className={className} color={color} width={width} height={height} />
    case 'kludd-12':
      return <Kludd12 className={className} color={color} width={width} height={height} />
    case 'kludd-13':
      return <Kludd13 className={className} color={color} width={width} height={height} />
    case 'kludd-14':
      return <Kludd14 className={className} color={color} width={width} height={height} />
    case 'kludd-15':
      return <Kludd15 className={className} color={color} width={width} height={height} />
    case 'kludd-16':
      return <Kludd16 className={className} color={color} width={width} height={height} />
    case 'kludd-17':
      return <Kludd17 className={className} color={color} width={width} height={height} />
    case 'kludd-18':
      return <Kludd18 className={className} color={color} width={width} height={height} />
    case 'kludd-19':
      return <Kludd19 className={className} color={color} width={width} height={height} />
    default:
      return null
  }
}

export default KluddIllustration
