import React from 'react'
import { FormFields } from '../FormFields'
import * as styles from '../FormCss.css'
import { ErrorMessage } from '@hookform/error-message'
import { UseFormRegisterReturn } from 'react-hook-form'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { Box, Text } from 'atomic'

interface PropTypes {
  errors: { [key: string]: string }
  register: UseFormRegisterReturn
  required?: boolean
}

const Description: React.FC<PropTypes> = ({ errors, register, required }) => {
  const { t } = useSafeTranslation()
  return (
    <Box className={`${styles.inputContainerWithFloatingLabel} ${styles.fullWidth}`}>
      <textarea
        id={FormFields.Description.id}
        {...register}
        rows={8}
        name={FormFields.Description.id}
        placeholder={t('form_description_label')}
        className={[...(errors[FormFields.Description.id] ? [styles.invalid, styles.textareaElement] : [styles.textareaElement])].join(' ')}
      />
      <label className={required ? `${styles.required} ${styles.labelElement}` : `${styles.labelElement}`} htmlFor={FormFields.Description.id}>
        {t('form_description_label')}
      </label>
      {Object.keys(errors).indexOf(FormFields.Description.id) === -1 && (
        <Text fontSize="xs" paddingLeft="xs" paddingTop="xxxs" marginBottom="none">
          {t('form_description_description')}
        </Text>
      )}
      <ErrorMessage
        errors={errors}
        name={FormFields.Description.id}
        render={({ message }) => (
          <Text fontSize="xs" fontWeight="normal" color="errorText">
            {message}
          </Text>
        )}
      />
    </Box>
  )
}

export default Description
