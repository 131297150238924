import React from 'react'
import { useSafeTranslation } from 'hooks/useSafeTranslation'

const SucceedView = () => {
  const { t } = useSafeTranslation()
  return (
    <>
      <h1>{t('form_success_message_title')}</h1>
      <p>{t('form_success_message_body')}</p>
    </>
  )
}

export default SucceedView
