import React from 'react'
import * as styles from '../FormCss.css'
import Illustration, { IllustrationType } from 'atomic/components/atoms/illustrations/Illustration'
import { Box, Text } from 'atomic'

interface PropTypes {
  header: string
  text: string
  illustrationType?: IllustrationType
}

const Information: React.FC<PropTypes> = ({ header, text, illustrationType }) => {
  return (
    <Box className={styles.helpContainer}>
      <Illustration name={illustrationType} width={80} height={80} />
      <Box>
        {header && (
          <Text fontSize="l" fontWeight="bold" marginBottom="m">
            {header}
          </Text>
        )}
        <Text fontWeight="normal" fontSize="s">
          {text}
        </Text>
      </Box>
    </Box>
  )
}

export default Information
